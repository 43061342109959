import { Modal, Select, Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';
import numeral from '../reusable/numeralLocale';
import { ConfirmModal } from '../reusable/PopupModal';
import { Toast } from '../reusable/Toast';

const { Option } = Select;

function TransactionsVerification() {
	const validateColumns = [
		{
			title: 'No',
			dataIndex: 'no',
			render: (text, record, index) => <>{index + 1}</>,
		},
		{
			title: 'Nama Perusahaan',
			render: (text, record) => (
				<div onClick={() => setCompanyModal(record.company)} className="text-secondary font-bold cursor-pointer">
					{record.company.nama_perusahaan}
				</div>
			),
		},
		{
			title: 'Tipe',
			render: (text, record) => <div className="capitalize">{record.products[0].tipe}</div>,
		},
		{
			title: 'Jenis',
			render: (text, record) => <div className="capitalize">{record.products[0].jenis}</div>,
		},
		{
			title: 'Detail',
			render: (text, record) => (
				<div
					onClick={() => setTransactionModal(record)}
					className="text-secondary font-bold flex justify-center items-center gap-2 cursor-pointer"
				>
					<div>Detail</div>
					<div>
						<img src="/images/dashboard/right-arrow.svg" className="w-6" alt="" />
					</div>
				</div>
			),
		},
		{
			title: 'Pada',
			render: (text, record) => <>{moment(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}</>,
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex justify-center items-center gap-4">
					<button
						onClick={() => onAccept(record.id)}
						className="py-2 px-4 rounded-full bg-green-custom-bright text-white font-bold"
					>
						Terima
					</button>
					<button
						onClick={() => onReject(record.id)}
						className="py-2 px-4 rounded-full bg-red-custom text-white font-bold"
					>
						Tolak
					</button>
				</div>
			),
		},
	];
	const confirmationColumns = [
		{
			title: 'No',
			dataIndex: 'no',
			render: (text, record, index) => <>{index + 1}</>,
		},
		{
			title: 'Nama Perusahaan',
			render: (text, record) => (
				<div onClick={() => setCompanyModal(record.company)} className="text-secondary font-bold cursor-pointer">
					{record.company.nama_perusahaan}
				</div>
			),
		},
		{
			title: 'Tipe',
			render: (text, record) => <div className="capitalize">{record.products[0].tipe}</div>,
		},
		{
			title: 'Jenis',
			render: (text, record) => <div className="capitalize">{record.products[0].jenis}</div>,
		},
		{
			title: 'Total',
			render: (text, record) => <>Rp. {numeral(record.total).format('0,0')}</>,
		},
		{
			title: 'Detail',
			render: (text, record) => (
				<div
					onClick={() => setTransactionModal(record)}
					className="text-secondary font-bold flex items-center gap-2 cursor-pointer"
				>
					<div>Detail</div>
					<div>
						<img src="/images/dashboard/right-arrow.svg" className="w-6" alt="" />
					</div>
				</div>
			),
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex justify-center items-center gap-4">
					<button
						onClick={() => onAcceptConfirm(record.id)}
						className="py-2 px-4 rounded-full bg-green-custom-bright text-white font-bold"
					>
						Terima
					</button>
					<button
						onClick={() => onRejectConfirm(record.id)}
						className="py-2 px-4 rounded-full bg-red-custom text-white font-bold"
					>
						Tolak
					</button>
				</div>
			),
		},
	];

	const onAcceptConfirm = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin bukti yang dilampirkan benar?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/transactions/confirm`, {
					transaction_id: id,
					action: 'terima',
				});
				Toast.fire({
					icon: 'success',
					title: 'Berhasil menerima bukti pembayaran lowongan',
					iconColor: '#31AAE0',
				});
				fetchData();
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response.data.info ?? 'Terjadi suatu Error',
					iconColor: '#FA8072',
				});
			} finally {
				setLoading(false);
			}
		}
	};

	const onRejectConfirm = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'warning',
			title: 'Apakah anda yakin ingin konfirmasi pembayaran lowongan ini?',
			iconColor: '#FA8072',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/transactions/confirm`, {
					transaction_id: id,
					action: 'tolak',
				});
				Toast.fire({
					icon: 'success',
					title: 'Berhasil menolak bukti pembayaran lowongan',
					iconColor: '#31AAE0',
				});
				fetchData();
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response.data.info ?? 'Terjadi suatu Error',
					iconColor: '#FA8072',
				});
			} finally {
				setLoading(false);
			}
		}
	};
	const onAccept = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin ingin menerima lowongan ini?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/transactions/validate`, {
					transaction_id: id,
					action: 'terima',
				});
				Toast.fire({
					icon: 'success',
					title: 'Berhasil menerima lowongan',
					iconColor: '#31AAE0',
				});
				fetchData();
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response.data.info ?? 'Terjadi suatu Error',
					iconColor: '#FA8072',
				});
			} finally {
				setLoading(false);
			}
		}
	};

	const onReject = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'warning',
			title: 'Apakah anda yakin ingin menolak lowongan ini?',
			iconColor: '#FA8072',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/transactions/validate`, {
					transaction_id: id,
					action: 'tolak',
				});
				Toast.fire({
					icon: 'success',
					title: 'Berhasil menolak lowongan',
					iconColor: '#31AAE0',
				});
				fetchData();
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response.data.info ?? 'Terjadi suatu Error',
					iconColor: '#FA8072',
				});
			} finally {
				setLoading(false);
			}
		}
	};

	const [paginationState, setPaginationState] = useState({
		current: 1,
		pageSize: 10,
	});
	const [loading, setLoading] = useState(false);
	const [buktiModal, setBuktiModal] = useState({});
	const [companyModal, setCompanyModal] = useState({});
	const [transactionModal, setTransactionModal] = useState({});
	const [data, setData] = useState([]);
	const [current, setCurrent] = useState('validate');

	useEffect(() => {
		fetchData();
	}, [current]);

	const fetchData = async () => {
		try {
			setLoading(true);
			let response;
			response = await axiosConfig.get(`${API_ENDPOINT}/request-verification/transactions`, {
				params: { status: current },
			});
			setData(response.data.data.transactions.data);
			setPaginationState({
				current: response.data.data.transactions.current_page,
				pageSize: response.data.data.transactions.per_page,
				total: response.data.data.transactions.total,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		if (isEmpty(sorter)) {
			fetchData(pagination, {});
		} else {
			fetchData(pagination, { [sorter.field]: sorter.order });
		}
	};
	const HeaderModal = ({ tipe }) => {
		return tipe === 'perusahaan' ? (
			<div className="my-8">
				<h1 className="font-bold text-2xl text-gray-custom">Detail Profil Perusahaan</h1>
				<h2 className="mt-1 text-gray-bright ">Isi form berikut untuk mengiklankan lowongan pekerjaan</h2>
			</div>
		) : (
			<div className="my-8">
				<h1 className="font-bold text-2xl text-gray-custom">Detail Transaksi</h1>
				<h2 className="mt-1 text-gray-bright ">Isi form berikut untuk mengiklankan lowongan pekerjaan</h2>
			</div>
		);
	};

	return (
		<>
			<Modal
				title={<HeaderModal />}
				footer={<span />}
				width={'60%'}
				visible={!isEmpty(companyModal)}
				onCancel={() => setCompanyModal({})}
				closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
			>
				<div className="grid grid-cols-4 mx-12 items-center justify-center text-lg gap-y-6">
					<p className="col-span-1 font-bold">Logo Perusahaan</p>
					<div className=" col-span-3">
						<img
							src={companyModal.profile_picture ?? `/images/dashboard/building-logo.svg`}
							className="w-56 rounded-full"
							alt=""
						/>
					</div>
					<p className="col-span-1 font-bold">Nama Perusahaan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.nama_perusahaan}</div>
					<p className="col-span-1 font-bold">Deskripsi Perusahaan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.deskripsi ?? '-'}</div>
					<p className="col-span-1 font-bold">Jumlah Karyawan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.jumlah_karyawan ?? '-'}
					</div>
					<p className="col-span-1 font-bold">Website</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.website ?? '-'}</div>
					<p className="col-span-1 font-bold">Tagline</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.tagline ?? '-'}</div>
					<p className="col-span-1 font-bold">Alamat</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.alamat ?? '-'}</div>
					<p className="col-span-1 font-bold">Bidang Perusahaan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.bidang_perusahaan ?? '-'}
					</div>
					<p className="col-span-1 font-bold">Visi</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.visi ?? '-'}</div>
					{companyModal.misi?.map((item, index) => (
						<React.Fragment key={index}>
							<div className="font-bold">{index === 0 && 'Misi'}</div>

							<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{item.text}</div>
						</React.Fragment>
					))}
				</div>
				<h1 className="mx-2 mt-8 font-bold text-2xl text-gray-custom">Perwakilan Perusahaan</h1>
				<div className="grid grid-cols-4 mx-12 items-center justify-center text-lg gap-y-6 mt-3">
					<p className="col-span-1 font-bold">Nama</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.user.name ?? '-'}
					</div>
					<p className="col-span-1 font-bold">Posisi</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.posisi_perusahaan}
					</div>
					<p className="col-span-1 font-bold">Email</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.user.email}
					</div>
					<p className="col-span-1 font-bold">Nomor HP</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.user.notel}
					</div>
				</div>
			</Modal>
			<Modal
				title={<span />}
				footer={<span />}
				width={'60%'}
				visible={!isEmpty(transactionModal)}
				onCancel={() => setTransactionModal({})}
				closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
			>
				<h1 className="text-3xl text-center font-bold text-gray-custom">Detail Transaksi</h1>
				{!isEmpty(transactionModal) && (
					<div className="my-6 border-2 border-gray-300 rounded-xl">
						<div className="flex flex-col lg:mx-4 my-6">
							<h4 className="text-lg text-gray-custom">Nomor Invoice</h4>
							<h4 className="text-lg font-bold text-gray-custom">INV-{transactionModal.invoice}</h4>
						</div>
						<div className="border-t-2 border-gray-300 w-full my-6"></div>
						<div className="flex flex-col lg:mx-4 my-6">
							<h4 className="text-lg text-gray-custom">Nama Pembeli</h4>
							<h4 className="text-lg font-bold text-gray-custom">{transactionModal.nama_pembeli}</h4>
						</div>
						<div className="border-t-2 border-gray-300 w-full my-6"></div>
						<div className="flex flex-col lg:mx-4 my-6">
							<h4 className="text-lg text-gray-custom">Handphone</h4>
							<h4 className="text-lg font-bold text-gray-custom">{transactionModal.notel}</h4>
						</div>
						<div className="border-t-2 border-gray-300 w-full my-6"></div>
						<div className="flex flex-col lg:mx-4 my-6">
							<h4 className="text-lg text-gray-custom">Produk</h4>
							<ul className="list-disc ml-6">
								{transactionModal.products.map((product, index) => (
									<li key={index} className="text-lg font-bold text-gray-custom capitalize">
										{product.tipe === 'subscription'
											? `${product.tipe} - ${product.jenis}`
											: `${product.tipe} - ${product.qty} Pcs`}
									</li>
								))}
							</ul>
						</div>
						<div className="border-t-2 border-gray-300 w-full my-6"></div>
						<div className="flex flex-col lg:flex-row justify-between items-center gap-4 lg:mx-4 my-6">
							<div>
								<h4 className="text-lg text-gray-custom mb-2">Transfer Bank</h4>
								<h4 className="text-lg font-bold text-gray-custom">A/n {transactionModal.payment.atas_nama}</h4>
							</div>
							<div>
								<img src={transactionModal.payment.gambar} className="w-16" alt="" />
							</div>
						</div>
						<div className="border-t-2 border-gray-300 w-full my-6"></div>
						<div className="flex flex-col lg:flex-row justify-between items-center gap-4 lg:mx-4 my-6">
							<div>
								<h4 className="text-lg text-gray-custom mb-2">Nomor Rekening</h4>
								<h4 className="text-lg font-bold text-gray-custom">{transactionModal.payment.nomor}</h4>
							</div>
						</div>
						<div className="border-t-2 border-gray-300 w-full my-6"></div>
						<div className="flex flex-col lg:mx-4 my-6">
							<h4 className="text-lg text-gray-custom">Total</h4>
							<h4 className="text-lg font-bold text-gray-custom">
								Rp. {numeral(transactionModal.total).format('0,0')}
							</h4>
						</div>
						{transactionModal.status === 'confirmed' && (
							<>
								<div className="border-t-2 border-gray-300 w-full my-6"></div>
								<div className="flex flex-col lg:mx-4 my-6">
									<h4 className="text-lg text-gray-custom">Bukti Pembayaran</h4>
									<img className="my-2 w-full" src={transactionModal.bukti_pembayaran} alt="" />
								</div>
								<div className="border-t-2 border-gray-300 w-full my-6"></div>
								<div className="flex flex-col lg:mx-4 my-6">
									<h4 className="text-lg text-gray-custom">Dibayar pada</h4>
									<h4 className="text-lg font-bold text-gray-custom">
										{moment(transactionModal.waktu_konfirmasi_pembayaran, 'YYYY-MM-DD HH:mm').format(
											'DD[,] MMMM YYYY [pukul] HH:mm'
										)}
									</h4>
								</div>
							</>
						)}
					</div>
				)}
			</Modal>

			{/* ---------- Main Content ---------- */}
			<div className="m-10 p-6 bg-white rounded-lg">
				<div className="flex justify-between">
					<div>
						<h1 className="text-3xl font-bold">Request Verifikasi</h1>
						<h2 className="text-xl mt-2">Permintaan verifikasi transaksi</h2>
					</div>
					<div className="flex items-center">
						<Select
							size="large"
							defaultValue="validate"
							style={{ width: 300 }}
							onChange={value => {
								setPaginationState({
									current: 1,
									pageSize: 10,
								});
								setCurrent(value);
							}}
						>
							<Option value="validate">Validasi Transaksi</Option>
							<Option value="confirmed">Konfirmasi Pembayaran</Option>
						</Select>
					</div>
				</div>
				<div className="w-full my-6">
					<Table
						columns={current === 'validate' ? validateColumns : confirmationColumns}
						rowKey={record => record.id}
						dataSource={data}
						pagination={paginationState}
						loading={loading}
						onChange={handleTableChange}
					/>
				</div>
			</div>
		</>
	);
}

export default TransactionsVerification;
