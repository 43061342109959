import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, InputNumber, Modal, Row, Select, TimePicker } from 'antd';
import { FieldArray, Formik } from 'formik';
import { debounce, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT, BASE_API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import numeral from '../../../../utils/numeralLocale';
import { lokasiKerjaData, pengalamanKerjaData, tipePekerjaanData } from '../../../../utils/selectData';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import SubmitButton from '../../../reusable/SubmitButton';
import { Toast } from '../../../reusable/Toast';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const validationSchema = Yup.object().shape({});

const HeaderModal = () => (
	<div className="flex gap-4">
		<div>
			<img src="/images/file-upload.svg" alt="Upload File" />
		</div>
		<div>
			<h1 className="font-bold text-2xl text-gray-custom">Buat Loker</h1>
			<h2 className="mt-1 text-gray-bright ">Klik Tombol Edit untuk mengubah data lowongan pekerjaan</h2>
		</div>
	</div>
);

const initialState = {
	posisi_pekerjaan: '',
	tipe_pekerjaan: '',
	gender: '',
	lokasi: '',
	district_id: '',
	pengalaman: '',
	job_desc: '',
	catatan: '',
	jam_kerja: '',
	batas_bawah_gaji: '',
	batas_atas_gaji: '',
	job_responsibilities: [],
	job_requirements: [],
	job_skills: [],
	islamic_env: false,
	job_benefits: [],
	expired_at: '',
};

function CreateLokerModal({ createModal, setCreateModal, fetchJobs }) {
	const [input, setInput] = useState(initialState);
	const [search, setSearch] = useState(false);
	const [district, setDistrict] = useState([]);
	const [errorMessage, setErrorMessage] = useState({});
	const dispatch = useDispatch();

	const onClose = () => {
		setCreateModal(false);
	};
	const onSubmit = async (values, { resetForm }) => {
		dispatch(setLoading());
		try {
			const formattedExpired = values.expired_at.format('DD-MM-YYYY');
			const response = await axiosConfig.post(`${API_ENDPOINT}/jobs`, { ...values, expired_at: formattedExpired });
			fetchJobs();
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
			onClose();
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};

	const onSearchDistrict = debounce(async value => {
		try {
			setSearch(true);
			let response = await axiosConfig.get(`${BASE_API_ENDPOINT}/perusahaan/wilayah/district`, {
				params: { search: value },
			});
			setDistrict(response.data.data.districts);
		} catch (err) {
			console.log(err);
		} finally {
			setSearch(false);
		}
	}, 500);

	const setJamKerja = (time, setFieldValue) => {
		let jamMasuk = time[0].format('HH:mm');
		let jamPulang = time[1].format('HH:mm');
		setFieldValue('jam_kerja', `${jamMasuk} - ${jamPulang}`);
	};

	const onChangeTanggal = (date, dateString, key, setFieldValue) => {
		setFieldValue(key, date);
	};

	useEffect(() => {
		onSearchDistrict();
	}, []);
	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={'85%'}
			visible={createModal}
			onOk={onSubmit}
			onCancel={onClose}
			closeIcon={false}
			destroyOnClose
		>
			{createModal && (
				<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
					{({
						isSubmitting,
						handleSubmit,
						handleBlur,
						handleChange,
						errors,
						touched,
						values,
						setFieldValue,
						setFieldTouched,
						isValid,
						dirty,
					}) => (
						<Form
							id="create-loker-modal"
							labelCol={{
								span: 5,
							}}
							wrapperCol={{
								span: 18,
							}}
							onFinish={handleSubmit}
							labelAlign="left"
							className="lg:ml-4 mb-12"
						>
							{/* POSISI PEKERJAAN */}
							<FormItem
								label="Posisi Pekerjaan"
								error={getErrorValue(errors.posisi_pekerjaan, errorMessage?.posisi_pekerjaan)}
								touched={touched.posisi_pekerjaan}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.posisi_pekerjaan}
									name="posisi_pekerjaan"
									placeholder="Posisi Pekerjaan yang dibutuhkan"
								/>
							</FormItem>
							{/* JAM PEKERJAAN */}
							<FormItem
								label="Jam Kerja"
								error={getErrorValue(errors.jam_kerja, errorMessage?.jam_kerja)}
								touched={touched.jam_kerja}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<TimePicker.RangePicker
									format={'HH:mm'}
									onBlur={() => setFieldTouched('jam_kerja')}
									onChange={time => setJamKerja(time, setFieldValue)}
									className="py-2"
								/>
							</FormItem>

							{/* TIPE PEKERJAAN */}
							<FormItem
								label="Tipe"
								error={getErrorValue(errors.tipe_pekerjaan, errorMessage?.tipe_pekerjaan)}
								touched={touched.tipe_pekerjaan}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="tipe_pekerjaan"
									value={values.tipe_pekerjaan}
									defaultValue={values.tipe_pekerjaan}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('tipe_pekerjaan')}
									onChange={value => {
										setFieldValue('tipe_pekerjaan', value);
									}}
									size="large"
								>
									<Option value="" disabled>
										Pilih Tipe Pekerjaan
									</Option>
									{tipePekerjaanData.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* GENDER PEKERJAAN */}
							<FormItem
								label="Jenis Kelamin"
								error={getErrorValue(errors.gender, errorMessage?.gender)}
								touched={touched.gender}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="gender"
									value={values.gender}
									defaultValue={values.gender}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('gender')}
									onChange={value => {
										setFieldValue('gender', value);
									}}
									size="large"
								>
									<Option value="" disabled>
										Pilih Jenis Kelamin
									</Option>
									<Option value="L">Laki-laki</Option>
									<Option value="P">Perempuan</Option>
									<Option value="LP">Laki-laki & Perempuan</Option>
								</Select>
							</FormItem>
							{/* PENGALAMAN */}
							<FormItem
								label="Pengalaman"
								error={getErrorValue(errors.pengalaman, errorMessage?.pengalaman)}
								touched={touched.pengalaman}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="pengalaman"
									value={values.pengalaman}
									defaultValue={values.pengalaman}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('pengalaman')}
									onChange={value => {
										setFieldValue('pengalaman', value);
									}}
									size="large"
								>
									<Option value="" disabled>
										Pilih Pengalaman
									</Option>
									{pengalamanKerjaData.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* LOKASI PEKERJAAN */}
							<FormItem
								label="Lokasi Kerja"
								error={getErrorValue(errors.lokasi, errorMessage?.lokasi)}
								touched={touched.lokasi}
								className="mb-4"
							>
								<Select
									name="lokasi"
									value={values.lokasi}
									defaultValue={values.lokasi}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('lokasi')}
									onChange={value => {
										setFieldValue('lokasi', value);
									}}
									size="large"
								>
									<Option value="" disabled>
										Darimana Karyawan Bekerja
									</Option>
									{lokasiKerjaData.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* KECAMATAN PEKERJAAN */}
							{values.lokasi !== 'remote' && (
								<FormItem
									label="Tempat Bekerja"
									error={getErrorValue(errors.district_id, errorMessage?.district_id)}
									touched={touched.district_id}
									className="mb-4"
								>
									<Select
										name="district_id"
										value={values.district_id}
										defaultValue={values.district_id}
										style={{ width: '100%' }}
										onBlur={() => setFieldTouched('district_id')}
										onChange={value => {
											setFieldValue('district_id', value);
										}}
										size="large"
										showSearch
										onSearch={onSearchDistrict}
										loading={search}
										notFoundContent={<div className="flex justify-center items-center">Kecamatan Tidak ditemukan</div>}
										filterOption={false}
									>
										{!isEmpty(district) && (
											<Option value="" disabled>
												Pilih Kecamatan tempat kerja
											</Option>
										)}
										{district.map(item => (
											<Option key={item.id} value={item.id}>
												{item.name} {item.city.name ? ` - ${item.city.name}` : ''}
												{item.city.province?.name ? ` - ${item.city.province?.name}` : ''}
											</Option>
										))}
									</Select>
								</FormItem>
							)}
							{/* DESKRIPSI PEKERJAAN */}
							<FormItem
								label="Deskripsi Pekerjaan"
								error={getErrorValue(errors.job_desc, errorMessage?.job_desc)}
								touched={touched.job_desc}
								className="mb-4"
								wrapperCol={{ span: 18 }}
							>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.job_desc}
									name="job_desc"
									placeholder="Deskripsi Pekerjaan yang dibutuhkan"
									type="textarea"
								/>
							</FormItem>

							{/* RENTANG GAJI */}
							<FormItem label="Rentang Gaji" className="mb-4">
								<div className="flex items-center justify-between w-full">
									<FormItem
										error={getErrorValue(errors.batas_bawah_gaji, errorMessage?.batas_bawah_gaji)}
										touched={touched.batas_bawah_gaji}
										className="mb-0"
										style={{ width: '40%' }}
									>
										<InputNumber
											onChange={value => setFieldValue('batas_bawah_gaji', value)}
											parser={value => value.replace(/\./g, '')}
											formatter={value => numeral(value).format('0,0')}
											onBlur={handleBlur}
											name="batas_bawah_gaji"
											className="w-full"
											size="large"
											placeholder="1.000.000"
										/>
									</FormItem>
									<p className="text-gray-bright text-lg lg:text-2xl">sampai</p>
									<FormItem
										error={getErrorValue(errors.batas_atas_gaji, errorMessage?.batas_atas_gaji)}
										touched={touched.batas_atas_gaji}
										style={{ width: '40%' }}
										className="mb-0"
									>
										<InputNumber
											onChange={value => setFieldValue('batas_atas_gaji', value)}
											parser={value => value.replace(/\./g, '')}
											formatter={value => numeral(value).format('0,0')}
											onBlur={handleBlur}
											name="batas_atas_gaji"
											className="w-full"
											size="large"
											placeholder="3.000.000"
										/>
									</FormItem>
								</div>
							</FormItem>
							{/* RESPONSIBILITIES */}
							<FieldArray name="job_responsibilities">
								{({ insert, remove, push }) => (
									<>
										{values.job_responsibilities.map((job_responsibilities, index) => (
											<FormItem
												label={index === 0 && 'Tanggung Jawab'}
												wrapperCol={index !== 0 && { lg: { span: 18, offset: 5 } }}
												// Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
												error={getErrorValue(
													errors.job_responsibilities && errors.job_responsibilities[index],
													errorMessage?.job_responsibilities && errorMessage?.job_responsibilities[index]
												)}
												touched={touched.job_responsibilities && touched.job_responsibilities[index]}
												key={index}
											>
												<Input
													onChange={handleChange}
													onBlur={handleBlur}
													value={job_responsibilities}
													name={`job_responsibilities[${index}]`}
													placeholder="Masukkan Tanggung Jawab"
													suffix={
														<div
															onClick={() => {
																remove(index);
															}}
														>
															hapus
														</div>
													}
												/>
											</FormItem>
										))}
										<FormItem
											label={isEmpty(values.job_responsibilities) && 'Tanggung Jawab'}
											wrapperCol={{ lg: { span: 18, offset: isEmpty(values.job_responsibilities) ? 0 : 5 } }}
										>
											<div
												onClick={() => push('')}
												className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10"
											>
												<img src="/images/circle-plus.svg" alt="" className="w-8" />
												<p className="text-lg text-gray-500">Tambah Tanggung Jawab</p>
											</div>
										</FormItem>
									</>
								)}
							</FieldArray>
							{/* REQUIREMENTS */}
							<FieldArray name="job_requirements">
								{({ insert, remove, push }) => (
									<>
										{values.job_requirements.map((job_requirements, index) => (
											<FormItem
												label={index === 0 && 'Kualifikasi'}
												wrapperCol={index !== 0 && { lg: { span: 18, offset: 5 } }}
												// Disini untuk mengecek apakah job_requirements sudah berbentuk array atau tidak
												error={getErrorValue(
													errors.job_requirements && errors.job_requirements[index],
													errorMessage?.job_requirements && errorMessage?.job_requirements[index]
												)}
												touched={touched.job_requirements && touched.job_requirements[index]}
												key={index}
											>
												<Input
													onChange={handleChange}
													onBlur={handleBlur}
													value={job_requirements}
													name={`job_requirements[${index}]`}
													placeholder="Masukkan Kualifikasi"
													suffix={
														<div
															onClick={() => {
																remove(index);
															}}
														>
															hapus
														</div>
													}
												/>
											</FormItem>
										))}
										<FormItem
											label={isEmpty(values.job_requirements) && 'Kualifikasi'}
											wrapperCol={{ lg: { span: 18, offset: isEmpty(values.job_requirements) ? 0 : 5 } }}
										>
											<div
												onClick={() => push('')}
												className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10"
											>
												<img src="/images/circle-plus.svg" alt="" className="w-8" />
												<p className="text-lg text-gray-500">Tambah Kualifikasi</p>
											</div>
										</FormItem>
									</>
								)}
							</FieldArray>
							{/* SKILLS */}
							<FieldArray name="job_skills">
								{({ insert, remove, push }) => (
									<>
										{values.job_skills.map((job_skills, index) => (
											<FormItem
												label={index === 0 && 'Skill yang Dibutuhkan'}
												wrapperCol={index !== 0 && { lg: { span: 18, offset: 5 } }}
												// Disini untuk mengecek apakah job_skills sudah berbentuk array atau tidak
												error={getErrorValue(
													errors.job_skills && errors.job_skills[index],
													errorMessage?.job_skills && errorMessage?.job_skills[index]
												)}
												touched={touched.job_skills && touched.job_skills[index]}
												key={index}
											>
												<Input
													onChange={handleChange}
													onBlur={handleBlur}
													value={job_skills}
													name={`job_skills[${index}]`}
													placeholder="Masukkan Tanggung Jawab"
													suffix={
														<div
															onClick={() => {
																remove(index);
															}}
														>
															hapus
														</div>
													}
												/>
											</FormItem>
										))}
										<FormItem
											label={isEmpty(values.job_skills) && 'Skill yang DIbutuhkan'}
											wrapperCol={{ lg: { span: 18, offset: isEmpty(values.job_skills) ? 0 : 5 } }}
										>
											<div
												onClick={() => push('')}
												className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10"
											>
												<img src="/images/circle-plus.svg" alt="" className="w-8" />
												<p className="text-lg text-gray-500">Tambah Skill</p>
											</div>
										</FormItem>
									</>
								)}
							</FieldArray>
							{/* BENEFITS */}
							<FieldArray name="job_benefits">
								{({ insert, remove, push }) => (
									<>
										{values.job_benefits.map((job_benefits, index) => (
											<FormItem
												label={index === 0 && 'Benefit'}
												wrapperCol={index !== 0 && { lg: { span: 18, offset: 5 } }}
												// Disini untuk mengecek apakah job_benefits sudah berbentuk array atau tidak
												error={getErrorValue(
													errors.job_benefits && errors.job_benefits[index],
													errorMessage?.job_benefits && errorMessage?.job_benefits[index]
												)}
												touched={touched.job_benefits && touched.job_benefits[index]}
												key={index}
											>
												<Input
													onChange={handleChange}
													onBlur={handleBlur}
													value={job_benefits}
													name={`job_benefits[${index}]`}
													placeholder="Masukkan Benefit"
													suffix={
														<div
															onClick={() => {
																remove(index);
															}}
														>
															hapus
														</div>
													}
												/>
											</FormItem>
										))}
										<FormItem
											label={isEmpty(values.job_benefits) && 'Benefit'}
											wrapperCol={{ lg: { span: 18, offset: isEmpty(values.job_benefits) ? 0 : 5 } }}
										>
											<div
												onClick={() => push('')}
												className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10"
											>
												<img src="/images/circle-plus.svg" alt="" className="w-8" />
												<p className="text-lg text-gray-500">Tambah Benefit</p>
											</div>
										</FormItem>
									</>
								)}
							</FieldArray>
							{/* CATATAN PEKERJAAN */}
							<FormItem
								label="Catatan Tambahan"
								error={getErrorValue(errors.catatan, errorMessage?.catatan)}
								touched={touched.catatan}
								className="mb-4"
								wrapperCol={{ span: 18 }}
							>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.catatan}
									name="catatan"
									placeholder="Tulis Catatan Tambahan"
									type="textarea"
								/>
							</FormItem>
							<FormItem
								label="Aktif Hingga"
								className="mb-4"
								error={getErrorValue(errors.expired_at, errorMessage?.expired_at)}
								touched={touched.expired_at}
							>
								<DatePicker
									onChange={(date, dateString) => onChangeTanggal(date, dateString, 'expired_at', setFieldValue)}
									size="large"
									className="w-full"
									value={values.expired_at}
								/>
							</FormItem>
							{/* ISLAMIC ENVIRONMENT */}
							<FormItem
								label=""
								error={getErrorValue(errors.islamic_env, errorMessage?.islamic_env)}
								touched={touched.islamic_env}
								className="mb-4"
								wrapperCol={{ lg: { span: 18, offset: 5 } }}
							>
								<Checkbox
									onChange={e => setFieldValue('islamic_env', e.target.checked)}
									onBlur={() => setFieldTouched('islamic_env')}
									checked={values.islamic_env}
									name="islamic_env"
								>
									<div className="text-xl">Diperbolehkan menerapkan prinsip islam ketika bekerja?</div>
								</Checkbox>
							</FormItem>

							<Row className="mt-10">
								<Col span={23} lg={{ span: 18, offset: 5 }}>
									<SubmitButton className="py-4">Lanjutkan</SubmitButton>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			)}
		</Modal>
	);
}

export default CreateLokerModal;
