import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/reusable/Loading';
import { getUser } from '../store/user';

function Prerequest({ children }) {
	const user = useSelector(state => state.user);
	const { loading } = useSelector(state => state.loading);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!user.isLoggedIn) {
			dispatch(getUser());
		}
	}, []);
	return (
		<>
			{loading > 0 && <Loading />}
			{children}
		</>
	);
}

export default Prerequest;
