import axios from "axios";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { deleteUser } from "../../store/user";
import { API_ENDPOINT } from "../../utils/config";
import headers from "../../utils/headers";

function SideNav() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const pathNameFirst = window.location.pathname.split("/")[2];
  const pathNameSecond = window.location.pathname.split("/")[3];
  const [menu, setMenu] = useState({
    ban: pathNameFirst === "ban",
  });
  const dispatch = useDispatch();

  const setDisplayMenu = (name) => {
    setMenu({ ...menu, [name]: !menu[name] });
  };

  const logout = async () => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/logout`,
        {},
        {
          headers,
        }
      );
    } catch (e) {
      console.log(e);
    }

    dispatch(deleteUser());
    navigate("/login");
  };

  return (
    <div className="w-72 border-r-2 pt-10 min-w-min h-screen fixed bg-white z-10 overflow-y-auto">
      <div className="w-48 cursor-pointer mx-6">
        <img width={180} height={55} src="/images/maisyah.svg" alt="" />
      </div>

      <div className="flex flex-col border-b-2 py-6 lg:mx-6 xl:text-lg">
        <Link to={"/dashboard"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              isEmpty(pathNameFirst)
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  isEmpty(pathNameFirst)
                    ? `/images/dashboard/my-posts-active.svg`
                    : `/images/dashboard/my-posts.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                isEmpty(pathNameFirst) ? "text-secondary" : "text-gray-custom"
              }`}
            >
              Pra Regis
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/pencaker"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "pencaker"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "pencaker"
                    ? `/images/sidenav/pencaker-active.svg`
                    : `/images/sidenav/pencaker.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "pencaker"
                  ? "text-secondary"
                  : "text-gray-custom"
              }`}
            >
              Pencaker
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/perusahaan"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "perusahaan"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "perusahaan"
                    ? `/images/sidenav/perusahaan-active.svg`
                    : `/images/sidenav/perusahaan.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "perusahaan"
                  ? "text-secondary"
                  : "text-gray-custom"
              }`}
            >
              Perusahaan
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/jobs"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "jobs"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "jobs"
                    ? `/images/dashboard/ls_bag-active.svg`
                    : `/images/dashboard/ls_bag.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "jobs" ? "text-secondary" : "text-gray-custom"
              }`}
            >
              Lowongan
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/transactions-verification"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "transactions-verification"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "transactions-verification"
                    ? `/images/dashboard/checklist-active.svg`
                    : `/images/dashboard/checklist.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "transactions-verification"
                  ? "text-secondary"
                  : "text-gray-custom"
              }`}
            >
              Verifikasi Transaksi
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/jobs-verification"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "jobs-verification"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "jobs-verification"
                    ? `/images/dashboard/ls_bag-active.svg`
                    : `/images/dashboard/ls_bag.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "jobs-verification"
                  ? "text-secondary"
                  : "text-gray-custom"
              }`}
            >
              Verifikasi Lowongan
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/skills"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "skills"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "skills"
                    ? `/images/dashboard/dashicons_welcome-write-blog-active.svg`
                    : `/images/dashboard/dashicons_welcome-write-blog.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "skills"
                  ? "text-secondary"
                  : "text-gray-custom"
              }`}
            >
              Skills
            </div>
          </div>
        </Link>
        <Link to={"/dashboard/vouchers"}>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center ${
              pathNameFirst === "vouchers"
                ? "bg-secondary bg-opacity-20"
                : "hover:bg-secondary hover:bg-opacity-10"
            }`}
          >
            <div>
              <img
                src={
                  pathNameFirst === "vouchers"
                    ? `/images/sidenav/mdi_ticket-percent-active.svg`
                    : `/images/sidenav/mdi_ticket-percent.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold ${
                pathNameFirst === "vouchers"
                  ? "text-secondary"
                  : "text-gray-custom"
              }`}
            >
              Vouchers
            </div>
          </div>
        </Link>
        <div>
          <div
            className={`p-4 cursor-pointer flex gap-2 items-center hover:bg-secondary hover:bg-opacity-10`}
            onClick={() => setDisplayMenu("ban")}
          >
            <div>
              <img
                src={
                  menu.ban
                    ? `/images/dashboard/bi_trash-fill-active.svg`
                    : `/images/dashboard/bi_trash-fill-gray.svg`
                }
                className="w-8"
                alt=""
              />
            </div>
            <div
              className={`font-bold hover:bg-secondary hover:bg-opacity-10 ${
                menu.ban ? "text-secondary" : "text-gray-custom"
              }`}
            >
              Ban
            </div>
            <div className={`flex-grow flex justify-end items-center`}>
              <CSSTransition
                in={menu.ban}
                timeout={200}
                classNames="arrow-"
                unmountOnExit={false}
              >
                <img
                  src="/images/dashboard/arrow-down.png"
                  className="w-4"
                  alt=""
                />
              </CSSTransition>
            </div>
          </div>
          <CSSTransition
            in={menu.ban}
            timeout={200}
            classNames="menu-"
            unmountOnExit
          >
            <div className="pl-4">
              <Link to={"/dashboard/ban/pencaker"}>
                <div
                  className={`p-4 cursor-pointer flex gap-2 items-center ${
                    pathNameFirst === "ban" && pathNameSecond === "pencaker"
                      ? "bg-secondary bg-opacity-20"
                      : "hover:bg-secondary hover:bg-opacity-10"
                  }`}
                >
                  <div>
                    <img
                      src={
                        pathNameFirst === "ban" && pathNameSecond === "pencaker"
                          ? `/images/sidenav/pencaker-active.svg`
                          : `/images/sidenav/pencaker.svg`
                      }
                      className="w-8"
                      alt=""
                    />
                  </div>
                  <div
                    className={`font-bold ${
                      pathNameFirst === "ban" && pathNameSecond === "pencaker"
                        ? "text-secondary"
                        : "text-gray-custom"
                    }`}
                  >
                    Pencaker
                  </div>
                </div>
              </Link>
              <Link to={"/dashboard/ban/perusahaan"}>
                <div
                  className={`p-4 cursor-pointer flex gap-2 items-center ${
                    pathNameFirst === "ban" && pathNameSecond === "perusahaan"
                      ? "bg-secondary bg-opacity-20"
                      : "hover:bg-secondary hover:bg-opacity-10"
                  }`}
                >
                  <div>
                    <img
                      src={
                        pathNameFirst === "ban" &&
                        pathNameSecond === "perusahaan"
                          ? `/images/sidenav/perusahaan-active.svg`
                          : `/images/sidenav/perusahaan.svg`
                      }
                      className="w-8"
                      alt=""
                    />
                  </div>
                  <div
                    className={`font-bold ${
                      pathNameFirst === "ban" && pathNameSecond === "perusahaan"
                        ? "text-secondary"
                        : "text-gray-custom"
                    }`}
                  >
                    Perusahaan
                  </div>
                </div>
              </Link>
            </div>
          </CSSTransition>
        </div>
        <div
          onClick={logout}
          className={`p-4 cursor-pointer flex gap-2 items-center font-bold hover:bg-secondary hover:bg-opacity-10`}
        >
          Logout
        </div>
        <a href={"/license.csv"}>
          <div className="p-4 cursor-pointer hover:text-primary hover:underline">
            Open Source Library
          </div>
        </a>
        <a href={"/full_license.txt"}>
          <div className="cursor-pointer hover:text-primary hover:underline">
            Open Source Library Full Licenses
          </div>
        </a>
      </div>
      <div className="flex flex-col items-center justify-center px-4 gap-2 my-12">
        <div>
          <img
            src={user.data?.profile_picture ?? "/images/dashboard/profile.png"}
            alt=""
          />
        </div>
        <div className="font-bold text-lg">{user.data?.name}</div>
        <div className="text-center text-sm">{user.data?.email}</div>
      </div>
    </div>
  );
}

export default SideNav;
