import { Checkbox, Col, DatePicker, Form, Modal, Row, Select } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import { Toast } from '../../../reusable/Toast';

const { Option } = Select;

const HeaderModal = () => (
	<div>
		<h1 className="font-bold text-2xl text-gray-custom">Tambah Skill</h1>
		<h2 className="mt-1 text-gray-bright ">Klik Tombol Buat untuk Membuat data Skill</h2>
	</div>
);

const initialState = {
	name: '',
	code: '',
	qty: '',
	type: '',
	discount: '',
	description: '',
	is_active: false,
	expired_at: moment(),
};

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Nama Voucher dibutuhkan'),
	code: Yup.string().required('Kode Voucher dibutuhkan'),
	qty: Yup.number().typeError('Kuantitas harus berupa angka').required('Kuantitas Voucher dibutuhkan'),
	discount: Yup.number().typeError('Kuantitas harus berupa angka').required('Discount Voucher dibutuhkan'),
	type: Yup.string().required('Tipe Voucher dibutuhkan'),
	description: Yup.string().nullable(),
	is_active: Yup.string().required('Status Voucher dibutuhkan'),
	expired_at: Yup.string().required('Waktu Expired Wajib diisi'),
});

function CreateVoucherModal({ createModal, setCreateModal, fetchData, paginationState }) {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState({});
	const [input, setInput] = useState(initialState);

	const onClose = () => {
		setCreateModal(false);
	};
	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/vouchers`, values);
			fetchData(paginationState);
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
			onClose();
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};

	const customFormat = value => value.lang('id').format('DD MMMM YYYY, [pukul] HH:mm');

	const onDatePick = (value, dateString) => {
		setFieldValue('expired_at', value.format('YYYY-MM-DD HH:mm'));
	};

	const {
		isSubmitting,
		handleSubmit,
		handleBlur,
		handleChange,
		errors,
		touched,
		values,
		setFieldValue,
		setFieldTouched,
		isValid,
		dirty,
	} = useFormik({
		initialValues: input,
		onSubmit,
		validationSchema,
		enableReinitialize: true,
	});

	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={700}
			visible={createModal}
			onOk={onSubmit}
			onCancel={onClose}
			closeIcon={false}
			destroyOnClose
		>
			<Form
				labelAlign="left"
				labelCol={{
					span: 6,
				}}
				wrapperCol={{
					span: 14,
				}}
				onFinish={handleSubmit}
			>
				{/* NAMA VOUCHER */}
				<FormItem
					label="Nama Voucher"
					error={getErrorValue(errors.name, errorMessage?.name)}
					touched={touched.name}
					className="mb-4"
				>
					<Input
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.name}
						name="name"
						placeholder="Nama Voucher"
						size={'default'}
					/>
				</FormItem>
				{/* CODE VOUCHER */}
				<FormItem
					label="Kode"
					error={getErrorValue(errors.code, errorMessage?.code)}
					touched={touched.code}
					className="mb-4"
				>
					<Input
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.code}
						name="code"
						placeholder="Kode ..."
						size={'default'}
					/>
				</FormItem>
				{/* KUANTITAS VOUCHER */}
				<FormItem
					label="Kuantitas"
					error={getErrorValue(errors.qty, errorMessage?.qty)}
					touched={touched.qty}
					className="mb-4"
				>
					<Input
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.qty}
						name="qty"
						placeholder="Kuantitas"
						size={'default'}
					/>
				</FormItem>
				{/* TIPE VOUCHER */}
				<FormItem
					label="Tipe Voucher"
					error={getErrorValue(errors.type, errorMessage?.type)}
					touched={touched.type}
					className="mb-4"
				>
					<Select
						name="type"
						value={values.type}
						defaultValue={values.type}
						style={{ width: '100%' }}
						onBlur={() => setFieldTouched('type')}
						onChange={value => {
							setFieldValue('type', value);
						}}
						size="large"
					>
						<Option value="" disabled>
							Pilih Jenis Tipe Voucher
						</Option>
						<Option key="0" value={'fix'}>
							Fix
						</Option>
						<Option key="1" value={'percentage'}>
							Percentage
						</Option>
					</Select>
				</FormItem>
				{/* DISCOUNT VOUCHER */}
				<FormItem
					label="Discount"
					error={getErrorValue(errors.discount, errorMessage?.discount)}
					touched={touched.discount}
					className="mb-4"
				>
					<Input
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.discount}
						name="discount"
						placeholder="Discount"
						size={'default'}
					/>
				</FormItem>
				<FormItem
					label="Aktif Hingga"
					className="mb-4"
					error={getErrorValue(errors.expired_at, errorMessage?.expired_at)}
					touched={touched.expired_at}
				>
					<DatePicker
						onChange={(date, dateString) => onDatePick(date, dateString)}
						format={customFormat}
						showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
						size="large"
						className="w-full"
						defaultValue={moment()}
						value={moment(values.expired_at, 'YYYY-MM-DD HH:mm')}
					/>
				</FormItem>
				{/* DESCRIPTION VOUCHER */}
				<FormItem
					label="Deskripsi"
					error={getErrorValue(errors.description, errorMessage?.description)}
					touched={touched.description}
					className="mb-4"
				>
					<Input
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.description}
						name="description"
						placeholder="Deskripsi"
						size={'default'}
						type="textarea"
					/>
				</FormItem>
				{/* Is Active */}
				<FormItem
					label="Is Active"
					error={getErrorValue(errors.is_active, errorMessage?.is_active)}
					touched={touched.is_active}
					className="mb-4"
				>
					<Checkbox
						onChange={e => setFieldValue('is_active', e.target.checked)}
						checked={values.is_active}
						name="is_active"
						size={'default'}
					/>
				</FormItem>
				<Row className="mt-6">
					<Col span={14} offset={6}>
						<button
							type="submit"
							className="w-full py-3 text-base border text-white  rounded-xl text-center flex gap-4 justify-center border-secondary bg-secondary hover:bg-opacity-90 cursor-pointer"
						>
							Simpan
						</button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}

export default CreateVoucherModal;
