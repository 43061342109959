import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import Input from '../../reusable/Input';
import { ConfirmModal } from '../../reusable/PopupModal';
import { Toast } from '../../reusable/Toast';

const SearchIcon = ({ onSearch }) => (
	<div onClick={onSearch}>
		<img src="/images/search.svg" className="w-5" />
	</div>
);

const initialPagination = {
	current: 1,
	pageSize: 10,
};

function BannedPerusahaan() {
	const [paginationState, setPaginationState] = useState(initialPagination);

	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			render: (text, record, index) => <>{(paginationState.current - 1) * paginationState.pageSize + index + 1}</>,
		},
		{
			title: 'Nama Perusahaan',
			render: (text, record) => (
				<div className=" font-bold">
					{record.nama_perusahaan}
				</div>
			),
		},
		{
			title: 'Bidang Perusahaan',
			render: (text, record) => <div>{record.bidang_perusahaan ?? '-'}</div>,
		},
		{
			title: 'Handphone',
			render: (text, record) => <div>{record.user_company.user.notel}</div>,
		},
		{
			title: 'Email',
			render: (text, record) => <div>{record.user_company.user.email}</div>,
		},
		{
			title: 'Diban Pada',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text, record) => <>{moment(record.banned_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}</>,
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex justify-center gap-4 items-center">
					<button
						onClick={() => onRestore(record.id)}
						className="py-2 px-8 rounded-full border-2 border-green-custom bg-green-custom text-white font-bold"
					>
						Restore
					</button>
					<button
						onClick={() => onDelete(record.id)}
						className="py-2 px-8 rounded-full border-2 border-red-custom bg-red-custom text-white font-bold"
					>
						Delete
					</button>
				</div>
			),
		},
	];

	const onRestore = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin ingin restore User Perusahaan ini?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.post(`${API_ENDPOINT}/perusahaan/ban/${id}`, { action: 'restore' });
				Toast.fire({
					icon: 'success',
					title: response.data.info,
				});
				fetchData(paginationState);
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response?.data.info ?? 'Terjadi Suatu Error',
					iconColor: '#FA8072',
				});
			}
		}
	};

	const onDelete = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin ingin menghapus permanen User Perusahaan ini?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.delete(`${API_ENDPOINT}/perusahaan/${id}`);
				Toast.fire({
					icon: 'success',
					title: response.data.info,
				});
				fetchData(paginationState);
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response?.data.info ?? 'Terjadi Suatu Error',
					iconColor: '#FA8072',
				});
			}
		}
	};

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState({});
	const [search, setSearch] = useState('');

	useEffect(() => {
		fetchData(paginationState);
	}, []);

	const fetchData = async pagination => {
		try {
			setLoading(true);
			let response;
			response = await axiosConfig.get(`${API_ENDPOINT}/perusahaan/ban`, {
				params: { page: pagination.current, limit: pagination.pageSize, search },
			});
			setData(response.data.data.perusahaans.data);
			setTotal({
				total_perusahaan: response.data.data.total_perusahaan,
				user_increase: response.data.data.user_increase,
			});
			setPaginationState({
				current: response.data.data.perusahaans.current_page,
				pageSize: response.data.data.perusahaans.per_page,
				total: response.data.data.perusahaans.total,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		fetchData(pagination);
	};

	const onSearch = () => {
		fetchData(initialPagination);
	};

	return (
		<>
			{/* ---------- Header Content ---------- */}
			<div className="m-10 grid grid-rows-4 grid-cols-4 rounded-lg gap-x-6 gap-y-8 overflow-x-auto">
				<div className="row-span-4 col-span-3 bg-white p-6">
					<div className="flex justify-between items-center">
						<div>
							<h1 className="text-2xl font-bold text-gray-custom">Grafik Perusahaan</h1>
							<h2 className="text-xl text-gray-custom">Grafik pengguna sejauh ini</h2>
						</div>
						<div>
							<select name="" id="">
								<option value="">Desember</option>
							</select>
						</div>
					</div>
					<div className="h-104 bg-gray-300 mt-4">ini grafik user nantinya</div>
				</div>
				<div className="row-span-2 col-span-1 bg-white py-6 flex flex-col justify-center items-center gap-2 px-4">
					<div>
						<img className="w-20" src="/images/perusahaan/pengguna-maisyah.svg" alt="" />
					</div>
					<h1 className="text-gray-custom font-bold text-xl">{total.total_perusahaan} orang</h1>
					<h2 className="text-gray-bright text-lg font-semibold">Pengguna Maisyah.id</h2>
				</div>
				<div className="row-span-2 col-span-1 bg-white py-6 flex flex-col justify-center items-center gap-2 px-4">
					<div>
						<img className="w-20" src="/images/perusahaan/pengguna-maisyah-plus.svg" alt="" />
					</div>
					<h1 className="text-gray-custom font-bold text-xl">{total.user_increase} orang</h1>
					<h2 className="text-gray-bright text-lg text-center font-semibold">
						Kenaikan pengguna dari Bulan {moment().format('MMMM')}
					</h2>
				</div>
			</div>
			{/* ---------- Table Content ---------- */}
			<div className="m-10 p-6 bg-white rounded-lg">
				<div className="flex justify-between">
					<div>
						<h1 className="text-3xl font-bold">Daftar Perusahaan</h1>
						<h2 className="text-xl mt-2">Berikut list perusahaan</h2>
					</div>
					<div className="flex gap-6 items-center">
						<Input
							size="default"
							suffix={<SearchIcon onSearch={() => onSearch()} />}
							placeholder="Search.."
							onChange={e => setSearch(e.target.value)}
							onPressEnter={() => onSearch()}
						/>
					</div>
				</div>
				<div className="w-full my-6">
					<Table
						columns={columns}
						rowKey={record => record.id}
						dataSource={data}
						pagination={paginationState}
						loading={loading}
						onChange={handleTableChange}
					/>
				</div>
			</div>
		</>
	);
}

export default BannedPerusahaan;
