import { Select, Table } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';
import { Toast } from '../reusable/Toast';

const { Option } = Select;

function Dashboard() {
	let token = localStorage.getItem('token');
	const columnsPencaker = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
			width: '10%',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: true,
			width: '30%',
		},
		{
			title: 'Handphone',
			dataIndex: 'notel',
			width: '30%',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '30%',
		},
	];

	const columnsPerusahaan = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: true,
			width: '10%',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: true,
			width: '20%',
		},
		{
			title: 'Nama Perusahaan',
			dataIndex: 'nama_perusahaan',
			width: '20%',
		},
		{
			title: 'Jabatan',
			dataIndex: 'jabatan',
			width: '15%',
		},
		{
			title: 'Handphone',
			dataIndex: 'notel',
			width: '15%',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '20%',
		},
	];

	const [paginationState, setPaginationState] = useState({
		current: 1,
		pageSize: 10,
	});
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState('pencaker');
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchData(paginationState);
	}, [current]);

	const fetchData = async (pagination, sorter) => {
		try {
			setLoading(true);
			let response;
			if (current === 'pencaker') {
				response = await axiosConfig.get(`${API_ENDPOINT}/pre_regis/pencaker`, {
					params: { page: pagination.current, ...sorter },
				});
				setData(response.data.data.pencaker.data);
				setPaginationState({
					current: response.data.data.pencaker.current_page,
					pageSize: response.data.data.pencaker.per_page,
					total: response.data.data.pencaker.total,
				});
			} else {
				response = await axiosConfig.get(`${API_ENDPOINT}/pre_regis/perusahaan`, {
					params: { page: pagination.current, ...sorter },
				});
				setData(response.data.data.perusahaan.data);
				setPaginationState({
					current: response.data.data.perusahaan.current_page,
					pageSize: response.data.data.perusahaan.per_page,
					total: response.data.data.perusahaan.total,
				});
			}
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		if (isEmpty(sorter)) {
			fetchData(pagination, {});
		} else {
			fetchData(pagination, { [sorter.field]: sorter.order });
		}
	};

	return (
		<div className="m-10 p-6 bg-white rounded-lg">
			<div className="flex justify-between">
				<div>
					<h1 className="text-3xl font-bold">Pra Regis</h1>
					<h2 className="text-xl mt-2">Data Pra Registrasi Maisyah</h2>
				</div>
				<div className="flex items-center">
					<Select
						size="large"
						defaultValue="pencaker"
						style={{ width: 300 }}
						onChange={value => {
							setPaginationState({
								current: 1,
								pageSize: 10,
							});
							setCurrent(value);
						}}
					>
						<Option value="pencaker">Pencaker</Option>
						<Option value="perusahaan">Perusahaan</Option>
					</Select>
				</div>
			</div>
			<div className="w-full my-6">
				<Table
					columns={current === 'pencaker' ? columnsPencaker : columnsPerusahaan}
					rowKey={record => record.id}
					dataSource={data}
					pagination={paginationState}
					loading={loading}
					onChange={handleTableChange}
				/>
			</div>
		</div>
	);
}

export default Dashboard;
