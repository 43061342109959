import { Select } from 'antd';
import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const { Option } = Select;
const options = {
	responsive: true,
	plugins: {},
	scales: {},
	interaction: {
		intersect: false,
	},
};

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ChartPencaker({ setFilterBy, filterBy, data }) {
	const [dataChart, setDataChart] = useState({
		labels: [],
		datasets: [],
	});

	const handleChange = value => {
		setFilterBy(value);
	};

	useEffect(() => {
		if (!isEmpty(data)) {
			handleChangeDataChart();
		}
	}, [data]);

	const handleChangeDataChart = () => {
		let labels = data.user_chart.map(item => item.date); //mengambil urutan date nya
		if (filterBy === 'month') {
			labels = labels.map(label => moment(label, 'YY-MM').format('MMMM'));
		} else if (filterBy === 'week') {
			labels = labels.map(label => {
				const arrDate = label.split('-');
				return `${moment().year(arrDate[0]).isoWeek(arrDate[1]).startOf('week').format('DD MMM')} - ${moment()
					.year(arrDate[0])
					.isoWeek(arrDate[1])
					.endOf('week')
					.format('DD MMM')}`;
			});
		} else if (filterBy === 'day') {
			labels = labels.map(label => moment(label, 'YYYY-MM-DD').format('ddd, DD MMM YY'));
		}
		const datasets = [
			{
				label: 'Jumlah Pendaftar',
				data: data.user_chart.map(item => item.value),
				backgroundColor: '#31AAE0',
			},
		];
		setDataChart({
			labels,
			datasets,
			options,
		});
	};

	return (
		<div className="col-span-2 bg-white p-6">
			<div className="flex justify-between items-center">
				<div>
					<h1 className="text-2xl font-bold text-gray-custom">Grafik Pencaker</h1>
					<h2 className="text-xl text-gray-custom">Grafik pencaker sejauh ini</h2>
				</div>
				<div>
					<Select size="large" defaultValue={filterBy} style={{ width: 300 }} onChange={handleChange}>
						<Option value="day">Harian</Option>
						<Option value="week">Pekan</Option>
						<Option value="month">Bulan</Option>
					</Select>
				</div>
			</div>
			<div className="">
				<Bar data={dataChart} options={dataChart.options} />
			</div>
		</div>
	);
}

export default ChartPencaker;
