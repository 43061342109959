import { Modal, Select, Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';
import numeral from '../reusable/numeralLocale';
import { ConfirmModal } from '../reusable/PopupModal';
import { Toast } from '../reusable/Toast';

const { Option } = Select;

function JobsVerification() {
	const validateColumns = [
		{
			title: 'No',
			dataIndex: 'no',
			render: (text, record, index) => <>{index + 1}</>,
		},
		{
			title: 'Nama Perusahaan',
			render: (text, record) => (
				<div onClick={() => setCompanyModal(record.company)} className="text-secondary font-bold cursor-pointer">
					{record.company.nama_perusahaan}
				</div>
			),
		},
		{
			title: 'Jenis',
			render: (text, record) => <div className="capitalize">{record.jenis}</div>,
		},
		{
			title: 'Skala',
			render: (text, record) => <div className="uppercase">{record.skala_bisnis}</div>,
		},
		{
			title: 'Posisi',
			render: (text, record) => (
				<div
					onClick={() => setJobModal(record)}
					className="text-secondary font-bold flex justify-center items-center gap-2 cursor-pointer"
				>
					<div>{record.posisi_pekerjaan}</div>
					<div>
						<img src="/images/dashboard/right-arrow.svg" className="w-6" alt="" />
					</div>
				</div>
			),
		},
		{
			title: 'Pada',
			render: (text, record) => <>{moment(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}</>,
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex justify-center items-center gap-4">
					<button
						onClick={() => onAccept(record.id)}
						className="py-2 px-4 rounded-full bg-green-custom-bright text-white font-bold"
					>
						Terima
					</button>
					<button
						onClick={() => onReject(record.id)}
						className="py-2 px-4 rounded-full bg-red-custom text-white font-bold"
					>
						Tolak
					</button>
				</div>
			),
		},
	];

	const onAcceptConfirm = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin bukti yang dilampirkan benar?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/confirm`, {
				job_id: id,
				action: 'terima',
			});
			Toast.fire({
				icon: 'success',
				title: 'Berhasil menerima bukti pembayaran lowongan',
				iconColor: '#31AAE0',
			});
			fetchData();
		}
	};
	const onAccept = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin ingin menerima lowongan ini?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/jobs/validate`, {
				job_id: id,
				action: 'terima',
			});
			Toast.fire({
				icon: 'success',
				title: 'Berhasil menerima lowongan',
				iconColor: '#31AAE0',
			});
			fetchData();
		}
	};

	const onReject = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'warning',
			title: 'Apakah anda yakin ingin menolak lowongan ini?',
			iconColor: '#FA8072',
		});
		if (confirm.isConfirmed) {
			const response = await axiosConfig.post(`${API_ENDPOINT}/request-verification/jobs/validate`, {
				job_id: id,
				action: 'tolak',
			});
			Toast.fire({
				icon: 'success',
				title: 'Berhasil menolak lowongan',
				iconColor: '#31AAE0',
			});
			fetchData();
		}
	};

	const [paginationState, setPaginationState] = useState({
		current: 1,
		pageSize: 10,
	});
	const [loading, setLoading] = useState(false);
	const [buktiModal, setBuktiModal] = useState({});
	const [companyModal, setCompanyModal] = useState({});
	const [jobModal, setJobModal] = useState({});
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			setLoading(true);
			let response;
			response = await axiosConfig.get(`${API_ENDPOINT}/request-verification/jobs`);
			setData(response.data.data.jobs.data);
			setPaginationState({
				current: response.data.data.jobs.current_page,
				pageSize: response.data.data.jobs.per_page,
				total: response.data.data.jobs.total,
			});
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const HeaderModal = () => (
		<div className="my-8">
			<h1 className="font-bold text-2xl text-gray-custom">Detail Profil Perusahaan</h1>
			<h2 className="mt-1 text-gray-bright ">Isi form berikut untuk mengiklankan lowongan pekerjaan</h2>
		</div>
	);

	return (
		<>
			<Modal
				title={<HeaderModal />}
				footer={<span />}
				width={'60%'}
				visible={!isEmpty(companyModal)}
				onCancel={() => setCompanyModal({})}
				closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
			>
				<div className="grid grid-cols-4 mx-12 items-center justify-center text-xl gap-y-6">
					<p className="col-span-1 font-bold">Logo Perusahaan</p>
					<div className=" col-span-3">
						<img
							src={companyModal.profile_picture ?? `/images/dashboard/building-logo.svg`}
							className="w-56 rounded-full"
							alt=""
						/>
					</div>
					<p className="col-span-1 font-bold">Nama Perusahaan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.nama_perusahaan}</div>
					<p className="col-span-1 font-bold">Deskripsi Perusahaan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{companyModal.deskripsi ?? '-'}</div>
					<p className="col-span-1 font-bold">Nama Perwakilan</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.user.name ?? '-'}
					</div>
					<p className="col-span-1 font-bold">Posisi</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.posisi_perusahaan}
					</div>
					<p className="col-span-1 font-bold">Email</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.user.email}
					</div>
					<p className="col-span-1 font-bold">Nomor HP</p>
					<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
						{companyModal.user_company?.user.notel}
					</div>
				</div>
			</Modal>
			<Modal
				title={<span />}
				footer={<span />}
				width={'60%'}
				visible={!isEmpty(jobModal)}
				onCancel={() => setJobModal({})}
				closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
			>
				<div className="flex gap-8 mx-12 text-xl">
					<div className="w-1/2">
						<div className="">
							<div className="flex gap-6">
								<div>
									<img src={jobModal.company?.profile_picture} className="w-24 rounded-full" alt="" />
								</div>
								<div>
									<div className="text-2xl font-bold">{jobModal.posisi_pekerjaan}</div>
									<div className="text-primary text-xl mt-2">{jobModal.company?.nama_perusahaan}</div>
								</div>
							</div>
							<div className="mt-6 flex flex-col gap-4 text-lg">
								<div className="flex gap-4 items-center">
									<div className="-ml-0.5">
										<img src="/images/dashboard/building.svg" className="w-6" alt="" />
									</div>
									<h1 className="text-gray-custom capitalize">
										{jobModal.district?.name.toLowerCase()} - {jobModal.district?.city.name.toLowerCase()}
									</h1>
								</div>
								<div className="flex gap-4 items-center">
									<div>
										<img src="/images/dashboard/currency.svg" className="w-5" alt="" />
									</div>
									<h1 className="text-gray-custom">
										Rp {numeral(jobModal.batas_bawah_gaji).format('0,0')} - Rp{' '}
										{numeral(jobModal.batas_atas_gaji).format('0,0')} / bulan
									</h1>
								</div>
								<div className="flex gap-4 items-center">
									<div>
										<img src="/images/dashboard/job.svg" className="w-5" alt="" />
									</div>
									<h1 className="text-gray-custom">Pengalaman {jobModal.pengalaman}</h1>
								</div>
								<div className="flex gap-4 items-center">
									<div>
										<img src="/images/dashboard/sand-time.svg" className="w-5" alt="" />
									</div>
									<h1 className="text-gray-custom capitalize">{jobModal.tipe_pekerjaan}</h1>
								</div>
							</div>
						</div>
						<div className="my-6">
							<h1 className="font-bold text-xl text-gray-custom">Deskripsi Pekerjaan</h1>
							<div className="my-2">
								<p>{jobModal.job_desc}</p>
							</div>
						</div>
						<div className="my-6">
							<h1 className="font-bold text-xl text-gray-custom">Responsibilities</h1>
							<ul className="list-disc ml-8 my-2 flex flex-col gap-2">
								{jobModal.job_responsibilities?.map(responsibilty => (
									<li>{responsibilty.name}</li>
								))}
							</ul>
						</div>
						<div className="my-6">
							<h1 className="font-bold text-xl text-gray-custom">Requirements</h1>
							<ul className="list-disc ml-8 my-2 flex flex-col gap-2">
								{jobModal.job_requirements?.map(responsibilty => (
									<li>{responsibilty.name}</li>
								))}
							</ul>
						</div>
					</div>
					<div className="w-1/2">
						<div className="">
							<div className="text-xl font-bold mb-4">Skills</div>
							<div className="flex flex-wrap gap-4">
								{jobModal.job_skills?.map((skill, index) => (
									<div
										key={index}
										className="text-primary text-lg font-bold py-2 px-4 bg-primary bg-opacity-20 rounded-xl"
									>
										{skill.name}
									</div>
								))}
							</div>
						</div>
						<div className="my-6">
							<div className="text-xl font-bold my-2">Benefit / Keuntungan</div>
							<div className="grid grid-cols-2 gap-2">
								{jobModal.job_benefits?.map((benefit, index) => (
									<div key={index} className="flex gap-4 items-center">
										<div>
											<img src="/images/checklist-orange.svg" className="w-5" alt="" />
										</div>
										<div>{benefit.name}</div>
									</div>
								))}
							</div>
						</div>
						<div className="my-6">
							<div className="text-xl font-bold my-2">
								Apakah Diperbolehkan menerapkan prinsip islam ketika bekerja?
							</div>
							{jobModal.islamic_env  ? (
								<div className=" font-bold text-2xl text-green-custom">Boleh</div>
							) : (
								<div className=" font-bold text-2xl text-red-custom">Tidak Boleh</div>
							)}
						</div>
						<div className="my-6">
							<div className="text-xl font-bold my-2">Catatan Tambahan</div>
							<h1 className="text-lg text-gray-custom">{jobModal.catatan ?? 'tidak ada catatan tambahan'}</h1>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				title={<span />}
				footer={<span />}
				width={'60%'}
				visible={!isEmpty(buktiModal)}
				onCancel={() => setBuktiModal({})}
				closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
			>
				<h1 className="text-3xl my-6">
					Bukti Pembayaran Lowongan <span className="text-primary font-bold">{buktiModal.posisi_pekerjaan} </span>
					Pada <span className="font-bold">{buktiModal.company?.nama_perusahaan}</span>
				</h1>
				<div className="flex items-center justify-center my-8">
					<img src={buktiModal.bukti_pembayaran} className="w-64" alt="" />
				</div>
			</Modal>

			{/* ---------- Main Content ---------- */}
			<div className="m-10 p-6 bg-white rounded-lg">
				<div className="flex justify-between">
					<div>
						<h1 className="text-3xl font-bold">Request Verifikasi</h1>
						<h2 className="text-xl mt-2">Permintaan verifikasi tayang loker</h2>
					</div>
				</div>
				<div className="w-full my-6">
					<Table
						columns={validateColumns}
						rowKey={record => record.id}
						dataSource={data}
						pagination={paginationState}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
}

export default JobsVerification;
