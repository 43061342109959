import SideNav from './SideNav';

function Layout({ children }) {
	return (
		<div className="flex text-gray-custom">
			<div>
				<SideNav />
				<div className="w-72"></div>
			</div>
			<div className="w-full min-h-screen bg-gray-50">{children}</div>
		</div>
	);
}

export default Layout;
