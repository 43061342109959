import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import axiosConfig from '../utils/axiosConfig';

const initialState = {
	data: {},
	isLoggedIn: false,
};
const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getUser: (state, action) => {
			const authData = JSON.parse(localStorage.getItem('auth_data_admin'));
			state.data = authData;
			/*
			 *	jika saat di ambil data user kosong maka belum terlogin
			 *	dan jika ada datanya maka user sudah terlogin
			 *	state logged in ini dibutuhkan supaya ketika pages berubah, tidak selalu menjalankan
			 *	get user, karena jika menggunakan local storage
			 *	maka function ini akan terus terjalan
			 */
			if (!isEmpty(authData)) {
				state.isLoggedIn = true;
			}
			return state;
		},
		setUser: (state, action) => {
			localStorage.setItem('auth_data_admin', JSON.stringify(action.payload.user));
			localStorage.setItem('token_admin', action.payload.token);
			axiosConfig.defaults.headers.Authorization = `Bearer ${action.payload.token}`;
			state.data = action.payload.user;
			state.isLoggedIn = true;
			return state;
		},
		deleteUser: (state, action) => {
			state.data = {};
			state.isLoggedIn = false;
			localStorage.removeItem('auth_data_admin');
			localStorage.removeItem('token_admin');
			return state;
		},
	},
});

export const { getUser, setUser, deleteUser } = user.actions;
export default user.reducer;
