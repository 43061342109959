import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Select, Table } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import axiosConfig from "../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../utils/config";
import Input from "../../reusable/Input";
import { ConfirmModal } from "../../reusable/PopupModal";
import { Toast } from "../../reusable/Toast";
import CompanyModal from "./_partials/CompanyModal";
import CreateLokerModal from "./_partials/CreateLokerModal";
import EditLokerModal from "./_partials/EditLokerModal";

const { Option } = Select;

const SearchIcon = ({ onSearch }) => (
  <div onClick={onSearch}>
    <img src="/images/search.svg" className="w-5" />
  </div>
);

const initialPagination = {
  current: 1,
  pageSize: 10,
};

const initialSort = {
  field: null,
  sort: null,
};

function Jobs() {
  const [paginationState, setPaginationState] = useState(initialPagination);
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      render: (text, record, index) => (
        <>
          {(paginationState.current - 1) * paginationState.pageSize + index + 1}
        </>
      ),
    },
    {
      title: "Posisi Pekerjaan",
      render: (text, record) => (
        <p className="text-gray-custom font-bold cursor-pointer">
          {record.posisi_pekerjaan}
        </p>
      ),
    },
    {
      title: "Status",
      render: (text, record) => <div>{record.status}</div>,
    },
    {
      title: "Perusahaan",
      render: (text, record) => (
        <div
          onClick={() => setPerusahaanModal(record.company)}
          className="text-secondary font-bold cursor-pointer"
        >
          {record.company.nama_perusahaan}
        </div>
      ),
    },
    {
      title: "Dibuat Pada",
      sorter: true,
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <>
          {moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY, HH:mm"
          )}
        </>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="flex items-center gap-5">
          <button onClick={() => setJobData(record)} className="text-primary">
            <EditOutlined className="text-xl" />
          </button>
          <button
            onClick={() => onDelete(record.id)}
            className="text-red-custom"
          >
            <DeleteOutlined className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const onDelete = async (id) => {
    const confirm = await ConfirmModal.fire({
      icon: "warning",
      title: "Apakah anda yakin ingin menghapus lowongan ini?",
      text: "Lowongan yang dihapus untuk sementara tidak bisa dikembalikan",
      iconColor: "#FA8072",
      confirmButtonColor: "#FA8072",
      confirmButtonText: "Hapus",
    });
    if (confirm.isConfirmed) {
      setLoading(true);
      try {
        const response = await axiosConfig.delete(`${API_ENDPOINT}/jobs/${id}`);
        Toast.fire({
          icon: "success",
          title: "Berhasil menghapus lowongan",
          iconColor: "#31AAE0",
        });
        fetchData(paginationState);
      } catch (error) {
        console.log(error);
        Toast.fire({
          icon: "error",
          title: error.response.data.info ?? "Terjadi suatu Error",
          iconColor: "#FA8072",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [perusahaanModal, setPerusahaanModal] = useState({});
  const [jobData, setJobData] = useState({});
  const [createModal, setCreateModal] = useState(false);
  const [data, setData] = useState([]);
  const [filterBy, setFilterBy] = useState("month");
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState(initialSort);

  useEffect(() => {
    fetchData();
  }, [paginationState, sort, filterBy, search]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;
      response = await axiosConfig.get(`${API_ENDPOINT}/jobs`, {
        params: {
          page: paginationState.current,
          limit: paginationState.pageSize,
          search,
          groupBy: filterBy,
          ...sort,
        },
      });
      setData(response.data.data);
      setTotalData(response.data.data.jobs.total);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response?.data.info ?? "Terjadi Suatu Error",
        iconColor: "#FA8072",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (!isEmpty(sorter)) {
      setSort({ field: sorter.field, sort: sorter.order });
    }
    setPaginationState({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const onSearch = () => {
    setPaginationState(initialPagination);
  };

  return (
    <>
      <CompanyModal
        perusahaanModal={perusahaanModal}
        setPerusahaanModal={setPerusahaanModal}
      />
      <EditLokerModal
        fetchJobs={() => fetchData(paginationState)}
        jobData={jobData}
        setJobData={setJobData}
      />
      <CreateLokerModal
        fetchJobs={() => fetchData(paginationState)}
        createModal={createModal}
        setCreateModal={setCreateModal}
      />
      {/* ---------- Header Content ---------- */}
      <div className="m-10 grid grid-cols-2 rounded-lg gap-x-6 gap-y-8">
        {/* <ChartJobs data={data.job_charts} filterBy={filterBy} setFilterBy={setFilterBy} /> */}

        <div className="bg-white py-6 flex flex-col justify-center items-center gap-2 px-4">
          <div>
            <img
              className="w-20"
              src="/images/pencaker/pengguna-maisyah.svg"
              alt=""
            />
          </div>
          <h1 className="text-gray-custom font-bold text-xl">12 orang</h1>
          <h2 className="text-gray-bright text-lg font-semibold">
            Lowongan Maisyah.id
          </h2>
        </div>
        <div className="bg-white py-6 flex flex-col justify-center items-center gap-2 px-4">
          <div>
            <img
              className="w-20"
              src="/images/pencaker/pengguna-maisyah-plus.svg"
              alt=""
            />
          </div>
          <h1 className="text-gray-custom font-bold text-xl">20 orang</h1>
          <h2 className="text-gray-bright text-lg text-center font-semibold">
            Kenaikan lowongan dari Bulan {moment().format("MMMM")}
          </h2>
        </div>
      </div>
      {/* ---------- Table Content ---------- */}
      <div className="m-10 p-6 bg-white rounded-lg">
        <div className="flex justify-between">
          <div>
            <h1 className="text-3xl font-bold">Daftar Lowongan</h1>
            <h2 className="text-xl mt-2">Berikut list lowongan</h2>
          </div>
          <div className="flex gap-6 items-center">
            <Input
              size="default"
              suffix={<SearchIcon onSearch={onSearch} />}
              placeholder="Search.."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setCreateModal(true)}
            className="flex items-center justify-center gap-3 px-4 py-2 bg-secondary text-white font-bold rounded-xl"
          >
            <span className="text-2xl">+</span>
            <span>Tambah Lowongan</span>
          </button>
        </div>
        <div className="w-full my-6">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data.jobs?.data}
            pagination={{ ...paginationState, total: totalData }}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </>
  );
}

export default Jobs;
