const skalaBisnisData = [
	{
		value: 'personal',
		text: 'Personal',
		harga: '45.000',
	},
	{
		value: 'umkm',
		text: 'UMKM',
		harga: '75.000',
	},
	{
		value: 'pendidikan',
		text: 'Pendidikan',
		harga: '105.000',
	},
	{
		value: 'bisnis',
		text: 'Bisnis',
		harga: '135.000',
	},
];

const jenisIklanData = [
	{
		value: 'gratis',
		text: 'Gratis',
	},
	{
		value: 'premium',
		text: 'Premium',
	},
];

const tipePekerjaanData = [
	{
		value: 'fulltime',
		text: 'Full Time',
	},
	{
		value: 'parttime',
		text: 'Part Time',
	},
	{
		value: 'internship',
		text: 'Internship',
	},
	{
		value: 'freelance',
		text: 'Freelance',
	},
	{
		value: 'contract',
		text: 'Contract',
	},
];

const statusJob = [
	{ value: 'validate', text: 'Validate' },
	{ value: 'unpaid', text: 'Unpaid' },
	{ value: 'confirmed', text: 'Confirmed' },
	{ value: 'paid', text: 'Paid' },
	{ value: 'failed', text: 'Failed' },
	{ value: 'expired', text: 'Expired' },
];

const pengalamanKerjaData = [
	{
		value: '3 bulan',
		text: '3 Bulan',
	},
	{
		value: '6 bulan',
		text: '6 Bulan',
	},
	{
		value: '1 tahun',
		text: '1 Tahun',
	},
	{
		value: '2 tahun',
		text: '2 Tahun',
	},
	{
		value: '3 tahun',
		text: '3 Tahun',
	},
	{
		value: '5 tahun',
		text: '5 Tahun',
	},
	{
		value: '10 tahun',
		text: '10 Tahun',
	},
	{
		value: 'Lebih dari 10 tahun',
		text: 'Lebih dari 10 Tahun',
	},
];

const jumlahKaryawanData = [
	{
		value: '1 - 10 orang',
		text: '1 - 10 Orang',
	},
	{
		value: '11 - 50 orang',
		text: '11 - 50 Orang',
	},
	{
		value: '51 - 100 orang',
		text: '51 - 100 Orang',
	},
	{
		value: '101 - 200 orang',
		text: '101 - 200 Orang',
	},
	{
		value: '201 - 500 orang',
		text: '201 - 500 Orang',
	},
	{
		value: '501 - 1000 orang',
		text: '501 - 1000 Orang',
	},
	{
		value: '1001 - 5000 orang',
		text: '1001 - 5000 Orang',
	},
	{
		value: '5001 - 10000 orang',
		text: '5001 - 10000 Orang',
	},
	{
		value: 'lebih dari 10000 orang',
		text: 'Lebih dari 10000 Orang',
	},
];

const lokasiKerjaData = [
	{
		value: 'on_site',
		text: 'On Site',
	},
	{
		value: 'remote',
		text: 'Remote',
	},
	{
		value: 'remote_on_specific_location',
		text: 'Remote on Specific Location',
	},
	{
		value: 'hybrid',
		text: 'Hybrid',
	},
];

module.exports = {
	skalaBisnisData,
	jenisIklanData,
	tipePekerjaanData,
	pengalamanKerjaData,
	lokasiKerjaData,
	jumlahKaryawanData,
	statusJob,
};
