import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, InputNumber, Modal, Select, Spin, TimePicker } from 'antd';
import { Formik } from 'formik';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT, BASE_API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import numeral from '../../../../utils/numeralLocale';
import { lokasiKerjaData, pengalamanKerjaData, statusJob, tipePekerjaanData } from '../../../../utils/selectData';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import { Toast } from '../../../reusable/Toast';
import JobFieldArray from './JobFieldArray';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const validationSchema = Yup.object().shape({});

const HeaderModal = () => (
	<div className="flex gap-4">
		<div>
			<img src="/images/file-upload.svg" alt="Upload File" />
		</div>
		<div>
			<h1 className="font-bold text-2xl text-gray-custom">Lihat Loker</h1>
			<h2 className="mt-1 text-gray-bright ">Klik Tombol Edit untuk mengubah data lowongan pekerjaan</h2>
		</div>
	</div>
);

const initialState = {
	posisi_pekerjaan: '',
	tipe_pekerjaan: '',
	gender: '',
	lokasi: '',
	district_id: '',
	pengalaman: '',
	job_desc: '',
	catatan: '',
	jam_kerja: '',
	jam_kerja_awal: '',
	jam_kerja_akhir: '',
	jenis: '',
	batas_bawah_gaji: '',
	batas_atas_gaji: '',
	job_responsibilities: [],
	job_requirements: [],
	job_skills: [],
	job_benefits: [],
	islamic_env: 0,
	status: '',
};

function EditLokerModal({ jobData, setJobData, fetchJobs }) {
	const [input, setInput] = useState(initialState);
	const [search, setSearch] = useState(false);
	const [district, setDistrict] = useState([]);
	const [errorMessage, setErrorMessage] = useState({});
	const [edit, setEdit] = useState(false);
	const dispatch = useDispatch();

	const onClose = () => {
		setJobData({});
		setEdit(false);
	};
	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.put(`${API_ENDPOINT}/jobs/${jobData.id}`, values);
			setEdit(false);
			fetchJobs();
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};

	const onSearchDistrict = debounce(async value => {
		try {
			setSearch(true);
			let response = await axiosConfig.get(`${BASE_API_ENDPOINT}/perusahaan/wilayah/district`, {
				params: { search: value },
			});
			setDistrict(response.data.data.districts);
		} catch (err) {
			console.log(err);
		} finally {
			setSearch(false);
		}
	}, 500);

	useEffect(() => {
		if (!isEmpty(jobData) && jobData.district !== null) {
			onSearchDistrict(jobData.district.name);
		}
	}, [jobData]);

	useEffect(() => {
		if (!isEmpty(jobData)) {
			const arrJamKerja = parseJamKerja(jobData.jam_kerja);
			setInput({
				posisi_pekerjaan: jobData.posisi_pekerjaan,
				tipe_pekerjaan: jobData.tipe_pekerjaan,
				gender: jobData.gender,
				lokasi: jobData.lokasi,
				district_id: jobData.district_id ?? '',
				pengalaman: jobData.pengalaman,
				job_desc: jobData.job_desc,
				status: jobData.status,
				catatan: jobData.catatan,
				jam_kerja: jobData.jam_kerja,
				jam_kerja_awal: arrJamKerja[0],
				jam_kerja_akhir: arrJamKerja[1],
				batas_bawah_gaji: jobData.batas_bawah_gaji,
				batas_atas_gaji: jobData.batas_atas_gaji,
				job_responsibilities: jobData.job_responsibilities?.map(item => item.name),
				job_requirements: jobData.job_requirements?.map(item => item.name),
				job_skills: jobData.job_skills?.map(item => item.name),
				job_benefits: jobData.job_benefits?.map(item => item.name),
				expired_at: moment(jobData.expired_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'),
				islamic_env: jobData.islamic_env === 1 ? true : false,
			});
		}
	}, [jobData]);

	const onDatePick = (value, dateString, setFieldValue) => {
		setFieldValue('expired_at', value.format('YYYY-MM-DD HH:mm'));
	};

	const customFormat = value => value.lang('id').format('DD MMMM YYYY, [pukul] HH:mm');

	const parseJamKerja = time => {
		const arrJamKerja = time.split(' - ');
		return [moment(arrJamKerja[0], 'HH:mm'), moment(arrJamKerja[1], 'HH:mm')];
	};

	const setJamKerja = (time, setFieldValue) => {
		let jamMasuk = time[0].format('HH:mm');
		let jamPulang = time[1].format('HH:mm');
		setFieldValue('jam_kerja', `${jamMasuk} - ${jamPulang}`);
	};

	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={'85%'}
			visible={!isEmpty(jobData)}
			onOk={onSubmit}
			onCancel={onClose}
			closeIcon={false}
			destroyOnClose
		>
			{!isEmpty(jobData) && (
				<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
					{({
						isSubmitting,
						handleSubmit,
						handleBlur,
						handleChange,
						errors,
						touched,
						values,
						setFieldValue,
						setFieldTouched,
						isValid,
						dirty,
					}) => (
						<Form
							id="create-loker-modal"
							labelCol={{
								span: 5,
							}}
							wrapperCol={{
								span: 18,
							}}
							labelAlign="left"
						>
							<div className="w-full flex justify-end mb-6">
								{edit ? (
									<button
										className={`flex gap-4 items-center justify-center border py-4 w-64 rounded-xl ${
											isSubmitting
												? 'border-gray-custom bg-gray-custom border-opacity-20 bg-opacity-20 cursor-not-allowed'
												: 'bg-secondary border-secondary text-white cursor-pointer'
										} `}
										type="submit"
										onClick={handleSubmit}
										disabled={isSubmitting}
									>
										<img src={`/images/save-white.svg`} alt="" className="w-4" />

										<p className="font-bold text-lg">Simpan Perubahan</p>
										{isSubmitting && <Spin indicator={loadingIcon} />}
									</button>
								) : (
									<button
										className={`flex gap-4 items-center justify-center border border-secondary py-4 w-64 rounded-xl cursor-pointer text-secondary`}
										onClick={() => setEdit(true)}
									>
										<img src={`/images/pencil-company-profile.svg`} alt="" className="w-6" />

										<p className="font-bold text-lg">Edit Lowongan</p>
									</button>
								)}
							</div>
							{/* POSISI PEKERJAAN */}
							<FormItem
								label="Posisi Pekerjaan"
								error={getErrorValue(errors.posisi_pekerjaan, errorMessage?.posisi_pekerjaan)}
								touched={touched.posisi_pekerjaan}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.posisi_pekerjaan}
									name="posisi_pekerjaan"
									placeholder="Posisi Pekerjaan yang dibutuhkan"
									disabled={!edit}
								/>
							</FormItem>
							{/* JAM PEKERJAAN */}
							<FormItem
								label="Jam Kerja"
								error={getErrorValue(errors.jam_kerja, errorMessage?.jam_kerja)}
								touched={touched.jam_kerja}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<TimePicker.RangePicker
									format={'HH:mm'}
									onBlur={() => setFieldTouched('jam_kerja')}
									onChange={time => setJamKerja(time, setFieldValue)}
									className="py-2"
									defaultValue={[values.jam_kerja_awal, values.jam_kerja_akhir]}
									disabled={!edit}
								/>
							</FormItem>

							{/* TIPE PEKERJAAN */}
							<FormItem
								label="Tipe"
								error={getErrorValue(errors.tipe_pekerjaan, errorMessage?.tipe_pekerjaan)}
								touched={touched.tipe_pekerjaan}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="tipe_pekerjaan"
									value={values.tipe_pekerjaan}
									defaultValue={values.tipe_pekerjaan}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('tipe_pekerjaan')}
									onChange={value => {
										setFieldValue('tipe_pekerjaan', value);
									}}
									size="large"
									disabled={!edit}
								>
									<Option value="" disabled>
										Pilih Tipe Pekerjaan
									</Option>
									{tipePekerjaanData.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* GENDER PEKERJAAN */}
							<FormItem
								label="Jenis Kelamin"
								error={getErrorValue(errors.gender, errorMessage?.gender)}
								touched={touched.gender}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="gender"
									value={values.gender}
									defaultValue={values.gender}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('gender')}
									onChange={value => {
										setFieldValue('gender', value);
									}}
									size="large"
									disabled={!edit}
								>
									<Option value="" disabled>
										Pilih Jenis Kelamin
									</Option>
									<Option value="L">Laki-laki</Option>
									<Option value="P">Perempuan</Option>
									<Option value="LP">Laki-laki & Perempuan</Option>
								</Select>
							</FormItem>
							{/* PENGALAMAN */}
							<FormItem
								label="Pengalaman"
								error={getErrorValue(errors.pengalaman, errorMessage?.pengalaman)}
								touched={touched.pengalaman}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="pengalaman"
									value={values.pengalaman}
									defaultValue={values.pengalaman}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('pengalaman')}
									onChange={value => {
										setFieldValue('pengalaman', value);
									}}
									size="large"
									disabled={!edit}
								>
									<Option value="" disabled>
										Pilih Pengalaman
									</Option>
									{pengalamanKerjaData.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* LOKASI PEKERJAAN */}
							<FormItem
								label="Lokasi Kerja"
								error={getErrorValue(errors.lokasi, errorMessage?.lokasi)}
								touched={touched.lokasi}
								className="mb-4"
							>
								<Select
									name="lokasi"
									value={values.lokasi}
									defaultValue={values.lokasi}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('lokasi')}
									onChange={value => {
										setFieldValue('lokasi', value);
									}}
									size="large"
									disabled={!edit}
								>
									<Option value="" disabled>
										Darimana Karyawan Bekerja
									</Option>
									{lokasiKerjaData.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* KECAMATAN PEKERJAAN */}
							{values.lokasi !== 'remote' && (
								<FormItem
									label="Tempat Bekerja"
									error={getErrorValue(errors.district_id, errorMessage?.district_id)}
									touched={touched.district_id}
									className="mb-4"
								>
									<Select
										name="district_id"
										value={values.district_id}
										defaultValue={values.district_id}
										style={{ width: '100%' }}
										onBlur={() => setFieldTouched('district_id')}
										onChange={value => {
											setFieldValue('district_id', value);
										}}
										size="large"
										showSearch
										onSearch={onSearchDistrict}
										loading={search}
										notFoundContent={<div className="flex justify-center items-center">Kecamatan Tidak ditemukan</div>}
										filterOption={false}
										disabled={!edit}
									>
										{!isEmpty(district) && (
											<Option value="" disabled>
												Pilih Kecamatan tempat kerja
											</Option>
										)}
										{district.map(item => (
											<Option key={item.id} value={item.id}>
												{item.name} {item.city.name ? ` - ${item.city.name}` : ''}
												{item.city.province?.name ? ` - ${item.city.province?.name}` : ''}
											</Option>
										))}
									</Select>
								</FormItem>
							)}
							{/* DESKRIPSI PEKERJAAN */}
							<FormItem
								label="Deskripsi Pekerjaan"
								error={getErrorValue(errors.job_desc, errorMessage?.job_desc)}
								touched={touched.job_desc}
								className="mb-4"
								wrapperCol={{ span: 18 }}
							>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.job_desc}
									name="job_desc"
									placeholder="Deskripsi Pekerjaan yang dibutuhkan"
									type="textarea"
									disabled={!edit}
								/>
							</FormItem>
							{/* RENTANG GAJI */}
							<FormItem label="Rentang Gaji" className="mb-4">
								<div className="flex items-center justify-between w-full">
									<FormItem
										error={getErrorValue(errors.batas_bawah_gaji, errorMessage?.batas_bawah_gaji)}
										touched={touched.batas_bawah_gaji}
										className="mb-0"
										style={{ width: '40%' }}
									>
										<InputNumber
											onChange={value => setFieldValue('batas_bawah_gaji', value)}
											parser={value => value.replace(/\./g, '')}
											formatter={value => numeral(value).format('0,0')}
											onBlur={handleBlur}
											name="batas_bawah_gaji"
											className="w-full"
											size="large"
											placeholder="1.000.000"
											value={values.batas_bawah_gaji}
											disabled={!edit}
										/>
									</FormItem>
									<p className="text-gray-bright text-lg lg:text-2xl">sampai</p>
									<FormItem
										error={getErrorValue(errors.batas_atas_gaji, errorMessage?.batas_atas_gaji)}
										touched={touched.batas_atas_gaji}
										style={{ width: '40%' }}
										className="mb-0"
									>
										<InputNumber
											onChange={value => setFieldValue('batas_atas_gaji', value)}
											parser={value => value.replace(/\./g, '')}
											formatter={value => numeral(value).format('0,0')}
											onBlur={handleBlur}
											name="batas_atas_gaji"
											className="w-full"
											size="large"
											placeholder="3.000.000"
											value={values.batas_atas_gaji}
											disabled={!edit}
										/>
									</FormItem>
								</div>
							</FormItem>
							{/* RESPONSIBILITIES */}
							<JobFieldArray
								value={values.job_responsibilities}
								error={errors.job_responsibilities}
								touched={touched.job_responsibilities}
								errorMessage={errorMessage.job_responsibilities}
								handleBlur={handleBlur}
								edit={edit}
								handleChange={handleChange}
								name="job_responsibilities"
								label="Responsibilities"
							/>
							{/* REQUIREMENTS */}
							<JobFieldArray
								value={values.job_requirements}
								error={errors.job_requirements}
								touched={touched.job_requirements}
								errorMessage={errorMessage.job_requirements}
								handleBlur={handleBlur}
								edit={edit}
								handleChange={handleChange}
								name="job_requirements"
								label="Requirements"
							/>
							{/* SKILLS */}
							<JobFieldArray
								value={values.job_skills}
								error={errors.job_skills}
								touched={touched.job_skills}
								errorMessage={errorMessage.job_skills}
								handleBlur={handleBlur}
								edit={edit}
								handleChange={handleChange}
								name="job_skills"
								label="Skill yang Dibutuhkan"
							/>
							{/* BENEFITS */}
							<JobFieldArray
								value={values.job_benefits}
								error={errors.job_benefits}
								touched={touched.job_benefits}
								errorMessage={errorMessage.job_benefits}
								handleBlur={handleBlur}
								edit={edit}
								handleChange={handleChange}
								name="job_benefits"
								label="Benefit / Keuntungan"
							/>
							{/* CATATAN PEKERJAAN */}
							<FormItem
								label="Catatan Tambahan"
								error={getErrorValue(errors.catatan, errorMessage?.catatan)}
								touched={touched.catatan}
								className="mb-4"
								wrapperCol={{ span: 18 }}
							>
								<Input
									onChange={handleChange}
									disabled={!edit}
									onBlur={handleBlur}
									value={values.catatan}
									name="catatan"
									placeholder="Tulis Catatan Tambahan"
									type="textarea"
								/>
							</FormItem>
							<FormItem
								label="Aktif Hingga"
								className="mb-4"
								error={getErrorValue(errors.expired_at, errorMessage?.expired_at)}
								touched={touched.expired_at}
							>
								<DatePicker
									onChange={(date, dateString) => onDatePick(date, dateString, setFieldValue)}
									format={customFormat}
									showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
									size="large"
									className="w-full"
									disabled={!edit}
									defaultValue={moment(values.expired_at, 'YYYY-MM-DD HH:mm')}
								/>
							</FormItem>
							{/* TIPE PEKERJAAN */}
							<FormItem
								label="Status"
								error={getErrorValue(errors.status, errorMessage?.status)}
								touched={touched.status}
								className="mb-4"
								wrapperCol={{ span: 8 }}
							>
								<Select
									name="status"
									value={values.status}
									defaultValue={values.status}
									style={{ width: '100%' }}
									onBlur={() => setFieldTouched('status')}
									onChange={value => {
										setFieldValue('status', value);
									}}
									size="large"
									disabled={!edit}
								>
									<Option value="" disabled>
										Pilih Status
									</Option>
									{statusJob.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.text}
										</Option>
									))}
								</Select>
							</FormItem>
							{/* ISLAMIC ENVIRONMENT */}
							<FormItem
								label=""
								error={getErrorValue(errors.islamic_env, errorMessage?.islamic_env)}
								touched={touched.islamic_env}
								className="mb-4"
								wrapperCol={{ lg: { span: 18, offset: 5 } }}
							>
								<Checkbox
									onChange={e => setFieldValue('islamic_env', e.target.checked)}
									onBlur={() => setFieldTouched('islamic_env')}
									checked={values.islamic_env}
									name="islamic_env"
									disabled={!edit}
								>
									<div className="text-xl">Diperbolehkan menerapkan prinsip islam ketika bekerja?</div>
								</Checkbox>
							</FormItem>
						</Form>
					)}
				</Formik>
			)}
		</Modal>
	);
}

export default EditLokerModal;
