import { FieldArray } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { getErrorValue } from '../../../../utils/getErrors';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';

function JobFieldArray({ value, error, touched, handleChange, edit, handleBlur, errorMessage, name, label }) {
	return (
		<FieldArray name={name}>
			{({ insert, remove, push }) => (
				<>
					{value?.map((items, index) => {
						return (
							<FormItem
								label={index === 0 && label}
								wrapperCol={index !== 0 && { lg: { span: 18, offset: 5 } }}
								// Disini untuk mengecek apakah item sudah berbentuk array atau tidak
								error={getErrorValue(error && error[index], errorMessage && errorMessage[index])}
								touched={touched && touched[index]}
								key={index}
							>
								<Input
									onChange={handleChange}
									disabled={!edit}
									onBlur={handleBlur}
									value={items}
									name={`${name}[${index}]`}
									placeholder={`Masukkan ${label}`}
									suffix={
										edit && (
											<p
												onClick={() => {
													remove(index);
												}}
												className="text-red-custom"
											>
												hapus
											</p>
										)
									}
								/>
							</FormItem>
						);
					})}
					{edit && (
						<FormItem label={isEmpty(value) && label} wrapperCol={{ span: 18, offset: isEmpty(value) ? 0 : 5 }}>
							<div
								onClick={() => push('')}
								className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10"
							>
								<img src="/images/circle-plus.svg" alt="" className="w-8" />
								<p className="text-lg text-gray-500">Tambah {label}</p>
							</div>
						</FormItem>
					)}
				</>
			)}
		</FieldArray>
	);
}

export default JobFieldArray;
