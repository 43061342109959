let headers;

// if (typeof window !== "undefined") {
// 	headers = {
// 		Accept: 'application/json',
// 		'Content-Type': 'application/json',
// 		Authorization: `Bearer ${localStorage.getItem('token')}`,
// 	};
// }
headers = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
	Authorization: `Bearer ${localStorage.getItem('token')}`,
};

export default headers;
