import axios from 'axios';

const axiosConfig = axios.create({});

axiosConfig.defaults.headers.common['Accept'] = 'application/json';
axiosConfig.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('token_admin');
axiosConfig.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default axiosConfig;
