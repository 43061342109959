import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';

const HeaderModal = () => (
	<div className="mt-8">
		<h1 className="font-bold text-2xl text-gray-custom">Detail Perusahaan</h1>
		<h2 className="mt-1 text-gray-bright ">Berikut adalah detail perusahaan</h2>
	</div>
);

function CompanyModal({ perusahaanModal, setPerusahaanModal }) {
	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={1200}
			visible={!isEmpty(perusahaanModal)}
			onCancel={() => setPerusahaanModal({})}
			closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
		>
			<div className="grid grid-cols-4 mx-12 items-center justify-center text-xl gap-y-6">
				<p className="col-span-1 font-bold">Foto Profil</p>
				<div className=" col-span-3">
					<img
						src={perusahaanModal.profile_picture ?? `/images/perusahaan/user-circle.svg`}
						className="w-56 rounded-full"
						alt=""
					/>
				</div>
				<p className="col-span-1 font-bold">Status Subscription</p>
				{perusahaanModal.active_subscription ? (
					<div className="col-span-3 border border-gray-300 pl-5 py-2 rounded-lg text-green-custom">Aktif</div>
				) : (
					<div className="col-span-3 border border-gray-300 pl-5 py-2 rounded-lg text-red-custom">Tidak Aktif</div>
				)}
				<p className="col-span-1 font-bold">Subscription Expired</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{moment(perusahaanModal.expired_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
				</div>
				<p className="col-span-1 font-bold">Nama Perusahaan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.nama_perusahaan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Nama Perusahaan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.nama_perusahaan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Jumlah Karyawan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.jumlah_karyawan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Website</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.website ?? '-'}</div>
				<p className="col-span-1 font-bold">Tagline</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.tagline ?? '-'}</div>
				{!isEmpty(perusahaanModal.village) && (
					<>
						<p className="col-span-1 font-bold">Provinsi</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.district.city.province.name}
						</div>
						<p className="col-span-1 font-bold">Kota</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.district.city.name}
						</div>
						<p className="col-span-1 font-bold">Kecamatan</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.district.name}
						</div>
						<p className="col-span-1 font-bold">Kelurahan</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.name}
						</div>
					</>
				)}
				<p className="col-span-1 font-bold">Alamat</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.alamat ?? '-'}</div>
				<p className="col-span-1 font-bold">Deskripsi</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.deskripsi ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Visi</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.visi ?? '-'}</div>
				{perusahaanModal.misi?.map((misi, index) =>
					index === 0 ? (
						<>
							<p className="col-span-1 font-bold">Misi</p>
							<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{misi.text ?? '-'}</div>
						</>
					) : (
						<>
							<div></div>
							<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{misi.text ?? '-'}</div>
						</>
					)
				)}
				<p className="col-span-1 font-bold">Dibuat pada</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{moment(perusahaanModal.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}
				</div>
			</div>
			<h1 className="text-2xl font-bold text-gray-custom mx-4 mt-10 mb-6">Perwakilan Kantor</h1>
			<div className="grid grid-cols-4 mx-12 items-center justify-center text-xl gap-y-6">
				<p className="col-span-1 font-bold">Nama</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.user.name ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Posisi pada Perusahaan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.posisi_perusahaan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Email</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.user.email ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Handphone</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.user.notel ?? '-'}
				</div>
			</div>
		</Modal>
	);
}

export default CompanyModal;
