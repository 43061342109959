import { Checkbox, Col, Form, Modal, Row } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import { Toast } from '../../../reusable/Toast';

const HeaderModal = () => (
	<div>
		<h1 className="font-bold text-2xl text-gray-custom">Tambah Skill</h1>
		<h2 className="mt-1 text-gray-bright ">Klik Tombol Buat untuk Membuat data Skill</h2>
	</div>
);

const initialState = {
	name: '',
	is_active: false,
};

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Nama Skill dibutuhkan'),
});

function CreateSkillModal({ createModal, setCreateModal, fetchData, paginationState }) {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState({});
	const [input, setInput] = useState(initialState);

	const onClose = () => {
		setCreateModal(false);
	};
	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/skills`, values);
			fetchData(paginationState);
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
			onClose();
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};

	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={700}
			visible={createModal}
			onOk={onSubmit}
			onCancel={onClose}
			closeIcon={false}
			destroyOnClose
		>
			<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
				{({
					isSubmitting,
					handleSubmit,
					handleBlur,
					handleChange,
					errors,
					touched,
					values,
					setFieldValue,
					setFieldTouched,
					isValid,
					dirty,
				}) => (
					<Form
						labelAlign="left"
						labelCol={{
							span: 4,
						}}
						wrapperCol={{
							span: 18,
						}}
						onFinish={handleSubmit}
					>
						{/* NAMA SKILL */}
						<FormItem
							label="Nama Skill"
							error={getErrorValue(errors.name, errorMessage?.name)}
							touched={touched.name}
							className="mb-4"
						>
							<Input
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.name}
								name="name"
								placeholder="Nama Skill"
								size={'default'}
							/>
						</FormItem>
						{/* Is Active */}
						<FormItem
							label="Is Active"
							error={getErrorValue(errors.is_active, errorMessage?.is_active)}
							touched={touched.is_active}
							className="mb-4"
						>
							<Checkbox
								onChange={e => setFieldValue('is_active', e.target.checked)}
								checked={values.is_active}
								name="is_active"
								size={'default'}
							/>
						</FormItem>
						<Row className="mt-6">
							<Col lg={{ span: 18, offset: 4 }}>
								<button
									type="submit"
									className="w-full py-3 text-base border text-white  rounded-xl text-center flex gap-4 justify-center border-secondary bg-secondary hover:bg-opacity-90 cursor-pointer"
								>
									Simpan
								</button>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}

export default CreateSkillModal;
