import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { Toast } from '../../../reusable/Toast';

const HeaderModal = () => (
	<div className="mt-8">
		<h1 className="font-bold text-2xl text-gray-custom">Detail Profil Pencaker</h1>
		<h2 className="mt-1 text-gray-bright ">Dibuat Pada</h2>
	</div>
);

function EditPencakerModal({ pencakerModal, setPencakerModal, fetchData }) {
	const dispatch = useDispatch();

	const genderCheck = gender => {
		if (gender === 'L') {
			return 'Laki-laki';
		} else if (gender === 'P') {
			return 'Perempuan';
		} else {
			return '-';
		}
	};

	const onRefreshOtp = async () => {
		try {
			dispatch(setLoading());
			let response;
			response = await axiosConfig.post(`${API_ENDPOINT}/refresh-opt/${pencakerModal.id}`, {});
			setPencakerModal({ ...pencakerModal, verification_code: response.data.data.user.verification_code });
			fetchData();
			Toast.fire({
				icon: 'success',
				title: response.data?.info ?? 'Terjadi Suatu Error',
				iconColor: '#31AAE0',
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			dispatch(setDone());
		}
	};

	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={'1000px'}
			visible={!isEmpty(pencakerModal)}
			onCancel={() => setPencakerModal({})}
			closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
		>
			<div className="grid grid-cols-4 mx-12 items-center justify-center text-xl gap-y-6">
				<p className="col-span-1 font-bold">Foto Profil</p>
				<div className=" col-span-3">
					<img
						src={pencakerModal.profile_picture ?? `/images/pencaker/user-circle.svg`}
						className="w-56 rounded-full"
						alt=""
					/>
				</div>
				<p className="col-span-1 font-bold">Nama Pencaker</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{pencakerModal.name}</div>
				<p className="col-span-1 font-bold">Email</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{pencakerModal.email}</div>
				<p className="col-span-1 font-bold">Nomor HP</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{pencakerModal.notel}</div>
				<p className="col-span-1 font-bold">Jenis Kelamin</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{genderCheck(pencakerModal.user_meta?.gender)}
				</div>
				<p className="col-span-1 font-bold">Kewarganegaraan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{pencakerModal.user_meta?.kewarganegaraan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Deskripsi</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{pencakerModal.user_meta?.deskripsi ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Bidang</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{pencakerModal.user_meta?.bidang ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Domisili</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{pencakerModal.user_meta?.domisili ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Tanggal Lahir</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{moment(pencakerModal.user_meta?.tgl_lahir, 'YYYY-MM-DD').format('DD MMMM YYYY')}
				</div>
				<p className="col-span-1 font-bold">Kode OTP</p>
				<div className=" col-span-3 flex gap-4">
					<div className="w-3/4 border border-gray-300 pl-5 py-2 rounded-lg">{pencakerModal.verification_code}</div>
					<button
						onClick={onRefreshOtp}
						className=" w-1/4 min-w-fit border-2 border-green-500 text-green-500 py-2 rounded-lg"
					>
						Refresh
					</button>
				</div>
				<p className="col-span-1 font-bold">Dibuat pada</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{moment(pencakerModal.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}
				</div>
			</div>
		</Modal>
	);
}

export default EditPencakerModal;
