import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Form, Modal, Select, Spin } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import { Toast } from '../../../reusable/Toast';

const { Option } = Select;

const HeaderModal = () => (
	<div className="mt-8">
		<h1 className="font-bold text-2xl text-gray-custom">Detail Profil Perusahaan</h1>
		<h2 className="mt-1 text-gray-bright ">Dibuat Pada</h2>
	</div>
);

const validationSchema = Yup.object().shape({
	subscription_type: Yup.string().nullable(),
	job_quota: Yup.number().typeError('Kuota Lowongan Harus berupa Angka').required('Kuota Lowongan Wajib diisi'),
	expired_at: Yup.string().required('Waktu Expired Wajib diisi'),
});

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const initialState = {
	subscription_type: '',
	job_quota: '',
	expired_at: '',
};

function EditPerusahaanModal({ perusahaanModal, setPerusahaanModal, fetchData }) {
	const [input, setInput] = useState(initialState);
	const [edit, setEdit] = useState(false);
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState({});

	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.put(`${API_ENDPOINT}/perusahaan/status/${perusahaanModal.id}`, values);
			setEdit(false);
			setPerusahaanModal(response.data.data.perusahaan);
			fetchData();
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};
	const {
		isSubmitting,
		handleSubmit,
		handleBlur,
		handleChange,
		errors,
		touched,
		values,
		setFieldValue,
		setFieldTouched,
		isValid,
		dirty,
	} = useFormik({
		initialValues: input,
		onSubmit,
		validationSchema,
		enableReinitialize: true,
	});

	useEffect(() => {
		if (!isEmpty(perusahaanModal)) {
			setInput({
				subscription_type: perusahaanModal.subscription_type ?? '',
				job_quota: perusahaanModal.job_quota,
				expired_at: moment(perusahaanModal.expired_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'),
			});
		}
	}, [perusahaanModal]);

	const onRefreshOtp = async () => {
		try {
			dispatch(setLoading());
			let response;
			response = await axiosConfig.post(`${API_ENDPOINT}/refresh-opt/${perusahaanModal.user_company?.user.id}`, {});
			setPerusahaanModal({
				...perusahaanModal,
				user_company: {
					...perusahaanModal.user_company,
					user: { ...perusahaanModal.user_company.user, verification_code: response.data.data.user.verification_code },
				},
			});
			fetchData();
			Toast.fire({
				icon: 'success',
				title: response.data?.info ?? 'Terjadi Suatu Error',
				iconColor: '#31AAE0',
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			dispatch(setDone());
		}
	};

	const onDatePick = (value, dateString) => {
		setFieldValue('expired_at', value.format('YYYY-MM-DD HH:mm'));
	};

	const customFormat = value => value.lang('id').format('DD MMMM YYYY, [pukul] HH:mm');

	return (
		<Modal
			title={<HeaderModal />}
			footer={<span />}
			width={1200}
			visible={!isEmpty(perusahaanModal)}
			onCancel={() => setPerusahaanModal({})}
			closeIcon={<img src="/images/cross.svg" className="mt-6 -ml-2" alt="" />}
		>
			<div className="grid grid-cols-4 mx-12 items-center justify-center text-xl gap-y-6">
				<p className="col-span-1 font-bold">Foto Profil</p>
				<div className=" col-span-3">
					<img
						src={perusahaanModal.profile_picture ?? `/images/perusahaan/user-circle.svg`}
						className="w-56 rounded-full"
						alt=""
					/>
				</div>
				<p className="col-span-1 font-bold">Status Subscription</p>
				{perusahaanModal.active_subscription ? (
					<div className="col-span-3 border border-gray-300 pl-5 py-2 rounded-lg text-green-custom">Aktif</div>
				) : (
					<div className="col-span-3 border border-gray-300 pl-5 py-2 rounded-lg text-red-custom">Tidak Aktif</div>
				)}
				<p className="col-span-1 font-bold">Subscription Expired</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{moment(perusahaanModal.expired_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
				</div>
				<p className="col-span-1 font-bold">Nama Perusahaan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.nama_perusahaan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Nama Perusahaan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.nama_perusahaan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Jumlah Karyawan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.jumlah_karyawan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Website</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.website ?? '-'}</div>
				<p className="col-span-1 font-bold">Tagline</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.tagline ?? '-'}</div>
				{!isEmpty(perusahaanModal.village) && (
					<>
						<p className="col-span-1 font-bold">Provinsi</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.district.city.province.name}
						</div>
						<p className="col-span-1 font-bold">Kota</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.district.city.name}
						</div>
						<p className="col-span-1 font-bold">Kecamatan</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.district.name}
						</div>
						<p className="col-span-1 font-bold">Kelurahan</p>
						<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
							{perusahaanModal.village.name}
						</div>
					</>
				)}
				<p className="col-span-1 font-bold">Alamat</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.alamat ?? '-'}</div>
				<p className="col-span-1 font-bold">Deskripsi</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.deskripsi ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Visi</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{perusahaanModal.visi ?? '-'}</div>
				{perusahaanModal.misi?.map((misi, index) =>
					index === 0 ? (
						<>
							<p className="col-span-1 font-bold">Misi</p>
							<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{misi.text ?? '-'}</div>
						</>
					) : (
						<>
							<div></div>
							<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">{misi.text ?? '-'}</div>
						</>
					)
				)}

				<p className="col-span-1 font-bold">Dibuat pada</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{moment(perusahaanModal.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}
				</div>
			</div>
			<div className="border my-8 w-11/12 mx-auto " />
			<h1 className="text-2xl font-bold text-gray-custom mx-4 mb-6">Perwakilan Kantor</h1>
			<div className="grid grid-cols-4 mx-12 items-center justify-center text-xl gap-y-6">
				<p className="col-span-1 font-bold">Nama</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.user.name ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Posisi pada Perusahaan</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.posisi_perusahaan ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Email</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.user.email ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Handphone</p>
				<div className=" col-span-3 border border-gray-300 pl-5 py-2 rounded-lg">
					{perusahaanModal.user_company?.user.notel ?? '-'}
				</div>
				<p className="col-span-1 font-bold">Kode OTP</p>
				<div className=" col-span-3 flex gap-4">
					<div className="w-3/4 border border-gray-300 pl-5 py-2 rounded-lg">
						{perusahaanModal.user_company?.user.verification_code ?? '-'}
					</div>
					<button
						onClick={onRefreshOtp}
						className=" w-1/4 min-w-fit border-2 border-green-500 text-green-500 py-2 rounded-lg"
					>
						Refresh
					</button>
				</div>
			</div>
			<div className="border my-8 w-11/12 mx-auto mt-6" />
			<Form
				id="edit-company-modal"
				onFinish={handleSubmit}
				labelCol={{
					span: 5,
				}}
				wrapperCol={{
					span: 18,
				}}
				labelAlign="left"
			>
				<div className="flex justify-between items-center my-6">
					<h1 className="text-2xl font-bold text-gray-custom mx-4">Edit Status</h1>
					{edit ? (
						<button
							className={`flex gap-4 items-center justify-center border py-4 w-64 rounded-xl ${
								isSubmitting
									? 'border-gray-custom bg-gray-custom border-opacity-20 bg-opacity-20 cursor-not-allowed'
									: 'bg-secondary border-secondary text-white cursor-pointer'
							} `}
							type="submit"
							disabled={isSubmitting}
						>
							<img src={`/images/save-white.svg`} alt="" className="w-4" />

							<p className="font-bold text-lg">Simpan Perubahan</p>
							{isSubmitting && <Spin indicator={loadingIcon} />}
						</button>
					) : (
						<div
							className={`flex gap-4 items-center justify-center border border-secondary py-4 w-64 rounded-xl cursor-pointer text-secondary`}
							onClick={() => setEdit(true)}
						>
							<img src={`/images/pencil-company-profile.svg`} alt="" className="w-6" />

							<p className="font-bold text-lg">Edit Status</p>
						</div>
					)}
				</div>
				{/* TIPE LANGGANAN */}
				<div className="mx-12">
					<FormItem
						label="Tipe Langganan"
						error={getErrorValue(errors.subscription_type, errorMessage?.subscription_type)}
						touched={touched.subscription_type}
						className="mb-4"
						wrapperCol={{ offset: 1 }}
					>
						<Select
							name="subscription_type"
							value={values.subscription_type}
							defaultValue={values.subscription_type}
							style={{ width: '100%' }}
							onBlur={() => setFieldTouched('subscription_type')}
							onChange={value => {
								setFieldValue('subscription_type', value);
							}}
							size="large"
							disabled={!edit}
						>
							<Option value="" disabled>
								Pilih Jenis Iklan
							</Option>
							<Option key="0" value={'trial'}>
								Trial
							</Option>
							<Option key="1" value={'loker_art'}>
								Loker ART/Baby Sitter
							</Option>
							<Option key="2" value={'iklan_loker'}>
								Iklan Loker
							</Option>
							<Option key="3" value={'basic'}>
								Basic
							</Option>
							<Option key="4" value={'standart'}>
								Standart
							</Option>
							<Option key="5" value={'medium'}>
								Medium
							</Option>
							<Option key="6" value={'professional'}>
								Professional
							</Option>
						</Select>
					</FormItem>
					{/* DESKRIPSI PEKERJAAN */}
					<FormItem
						label="Kuota Lowongan"
						error={getErrorValue(errors.job_quota, errorMessage?.job_quota)}
						touched={touched.job_quota}
						className="mb-4"
						wrapperCol={{ offset: 1 }}
					>
						<Input
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.job_quota}
							name="job_quota"
							placeholder="Kuota Lowongan"
							disabled={!edit}
							size="middle"
						/>
					</FormItem>
					<FormItem
						label="Aktif Hingga"
						className="mb-4"
						error={getErrorValue(errors.expired_at, errorMessage?.expired_at)}
						touched={touched.expired_at}
						wrapperCol={{ offset: 1 }}
					>
						<DatePicker
							onChange={(date, dateString) => onDatePick(date, dateString)}
							format={customFormat}
							showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
							size="large"
							className="w-full"
							disabled={!edit}
							defaultValue={moment(values.expired_at, 'YYYY-MM-DD HH:mm')}
							value={moment(values.expired_at, 'YYYY-MM-DD HH:mm')}
						/>
					</FormItem>
				</div>
			</Form>
		</Modal>
	);
}

export default EditPerusahaanModal;
