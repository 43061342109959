import { Select, Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import Input from '../../reusable/Input';
import { ConfirmModal } from '../../reusable/PopupModal';
import { Toast } from '../../reusable/Toast';
import ChartPencaker from './_partials/ChartPencaker';
import EditPencakerModal from './_partials/EditPencakerModal';

const { Option } = Select;

const SearchIcon = ({ onSearch }) => (
	<div onClick={onSearch}>
		<img src="/images/search.svg" className="w-5" />
	</div>
);

const initialPagination = {
	current: 1,
	pageSize: 10,
};

const initialSort = {
	field: null,
	sort: null,
};

function Pencaker() {
	const [paginationState, setPaginationState] = useState(initialPagination);
	const [totalData, setTotalData] = useState(0);
	const [sort, setSort] = useState(initialSort);
	const [filterBy, setFilterBy] = useState('month');
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [pencakerModal, setPencakerModal] = useState({});
	const [data, setData] = useState([]);

	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			render: (text, record, index) => <>{(paginationState.current - 1) * paginationState.pageSize + index + 1}</>,
		},
		{
			title: 'Nama Pencaker',
			render: (text, record) => (
				<div onClick={() => setPencakerModal(record)} className="text-secondary font-bold cursor-pointer">
					{record.name}
				</div>
			),
		},
		{
			title: 'Email',
			render: (text, record) => <div>{record.email}</div>,
		},
		{
			title: 'Handphone',
			render: (text, record) => <div>{record.notel}</div>,
		},
		{
			title: 'Pada',
			sorter: true,
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text, record) => <>{moment(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}</>,
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex justify-center items-center">
					<button
						onClick={() => onBan(record.id)}
						className="py-2 px-8 rounded-full bg-red-custom text-white font-bold"
					>
						Ban
					</button>
				</div>
			),
		},
	];

	const onBan = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin Ban User Pencaker ini?',
			iconColor: '#31AAE0',
		});
		if (confirm.isConfirmed) {
			try {
				const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/ban/${id}`, { action: 'ban' });
				Toast.fire({
					icon: 'success',
					title: response.data.info,
				});
				fetchData();
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response?.data.info ?? 'Terjadi Suatu Error',
					iconColor: '#FA8072',
				});
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, [paginationState, sort, filterBy, search]);

	const fetchData = async () => {
		try {
			setLoading(true);
			let response;
			response = await axiosConfig.get(`${API_ENDPOINT}/pencaker`, {
				params: {
					page: paginationState.current,
					limit: paginationState.pageSize,
					search,
					groupBy: filterBy,
					...sort,
				},
			});
			setData(response.data.data);
			setTotalData(response.data.data.pencakers.total);
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		if (!isEmpty(sorter)) {
			setSort({ field: sorter.field, sort: sorter.order });
		}
		setPaginationState({
			current: pagination.current,
			pageSize: pagination.pageSize,
		});
	};

	const onSearch = () => {
		setPaginationState(initialPagination);
	};

	return (
		<>
			<EditPencakerModal fetchData={fetchData} pencakerModal={pencakerModal} setPencakerModal={setPencakerModal} />
			{/* ---------- Header Content ---------- */}
			<div className="m-10 grid grid-cols-2 rounded-lg gap-x-6 gap-y-8 ">
				<ChartPencaker data={data} filterBy={filterBy} setFilterBy={setFilterBy} />
				<div className="bg-white py-6 flex flex-col justify-center items-center gap-2 px-4">
					<div>
						<img className="w-20" src="/images/pencaker/pengguna-maisyah.svg" alt="" />
					</div>
					<h1 className="text-gray-custom font-bold text-xl">{data.total_pencaker} orang</h1>
					<h2 className="text-gray-bright text-lg font-semibold">Total Pengguna Maisyah.id</h2>
				</div>
				<div className="bg-white py-6 flex flex-col justify-center items-center gap-2 px-4">
					<div>
						<img className="w-20" src="/images/pencaker/pengguna-maisyah-plus.svg" alt="" />
					</div>
					<h1 className="text-gray-custom font-bold text-xl">{data.user_increase} orang</h1>
					<h2 className="text-gray-bright text-lg text-center font-semibold">
						Pengguna baru pada Bulan {moment().format('MMMM')}
					</h2>
				</div>
			</div>
			{/* ---------- Table Content ---------- */}
			<div className="m-10 p-6 bg-white rounded-lg">
				<div className="flex justify-between">
					<div>
						<h1 className="text-3xl font-bold">Daftar Pencaker</h1>
						<h2 className="text-xl mt-2">Berikut list pencari kerja</h2>
					</div>
					<div className="flex gap-6 items-center">
						<Input
							size="default"
							suffix={<SearchIcon onSearch={onSearch} />}
							placeholder="Search.."
							onChange={e => setSearch(e.target.value)}
						/>
					</div>
				</div>
				<div className="w-full my-6">
					<Table
						columns={columns}
						rowKey={record => record.id}
						dataSource={data?.pencakers?.data}
						pagination={{ ...paginationState, total: totalData }}
						loading={loading}
						onChange={handleTableChange}
					/>
				</div>
			</div>
		</>
	);
}

export default Pencaker;
