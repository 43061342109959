import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { ConfirmModal } from '../../reusable/PopupModal';
import { Toast } from '../../reusable/Toast';
import CreateVoucherModal from './_partials/CreateVoucherModal';
import EditVoucherModal from './_partials/EditVoucherModal';

function Vouchers() {
	const columns = [
		{
			title: 'No',
			key: 'no',
			dataIndex: 'no',
			render: (text, record, index) => <>{(paginationState.current - 1) * paginationState.pageSize + index + 1}</>,
		},
		{
			title: 'Nama',
			key: 'nama',
			render: (text, record) => <div>{record.name ?? '-'}</div>,
		},
		{
			title: 'Kode',
			key: 'kode',
			render: (text, record) => <div className="font-bold">{record.code}</div>,
		},
		{
			title: 'qty',
			key: 'qty',
			dataIndex: 'qty',
		},
		{
			title: 'status',
			key: 'status',
			render: (text, record) => <div>{record.is_active ? 'Aktif' : 'Tidak Aktif'}</div>,
		},
		{
			title: 'Dibuat Pada',
			sorter: true,
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text, record) => <>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}</>,
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex gap-5 items-center">
					<button onClick={() => setEditModal(record)} className="text-primary">
						<EditOutlined className="text-xl" />
					</button>
					<button onClick={() => onDelete(record.id)} className="text-red-custom">
						<DeleteOutlined className="text-xl" />
					</button>
				</div>
			),
		},
	];

	const onDelete = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin ingin menghapus Voucher?',
			iconColor: '#31AAE0',
			confirmButtonColor: '#FA8072',
			confirmButtonText: 'Yakin',
			cancelButtonColor: '#8aa2ae',
		});
		if (confirm.isConfirmed) {
			try {
				setLoading(true);
				const response = await axiosConfig.delete(`${API_ENDPOINT}/vouchers/${id}`);
				Toast.fire({
					icon: 'success',
					title: 'Berhasil Menghapus Voucher',
					iconColor: '#31AAE0',
				});
				fetchData(paginationState);
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response?.data.info ?? 'Terjadi Suatu Error',
					iconColor: '#FA8072',
				});
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		fetchData(paginationState);
	}, []);

	const [paginationState, setPaginationState] = useState({
		current: 1,
		pageSize: 10,
	});
	const [loading, setLoading] = useState(false);
	const [createModal, setCreateModal] = useState(false);
	const [editModal, setEditModal] = useState({});
	const [data, setData] = useState([]);

	const fetchData = async (pagination, sorter) => {
		try {
			setLoading(true);
			let response;
			response = await axiosConfig.get(`${API_ENDPOINT}/vouchers`, {
				params: { page: pagination.current, limit: pagination.pageSize },
			});
			setData(response.data.data.vouchers.data);
			setPaginationState({
				current: response.data.data.vouchers.current_page,
				pageSize: response.data.data.vouchers.per_page,
				total: response.data.data.vouchers.total,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		if (isEmpty(sorter)) {
			fetchData(pagination, {});
		} else {
			fetchData(pagination, { [sorter.field]: sorter.order });
		}
	};

	return (
		<div>
			<CreateVoucherModal
				createModal={createModal}
				setCreateModal={setCreateModal}
				fetchData={fetchData}
				paginationState={paginationState}
			/>
			<EditVoucherModal
				editModal={editModal}
				setEditModal={setEditModal}
				fetchData={fetchData}
				paginationState={paginationState}
			/>
			{/* ---------- Main Content ---------- */}
			<div className="m-10 p-6 bg-white rounded-lg">
				<div>
					<h1 className="text-3xl font-bold">Vouchers</h1>
					<h2 className="text-xl mt-2">List Vouchers yang bisa digunakan oleh user</h2>
				</div>
				<div className="flex justify-end">
					<button
						onClick={() => setCreateModal(true)}
						className="text-lg bg-secondary text-white border-2 border-secondary rounded-xl py-2 px-4 font-bold flex justify-center items-center gap-4"
					>
						<span className="text-3xl">+</span> <span>Tambah Voucher</span>
					</button>
				</div>
				<div className="w-full my-6">
					<Table
						columns={columns}
						rowKey={record => record.id}
						dataSource={data}
						pagination={paginationState}
						loading={loading}
						onChange={handleTableChange}
					/>
				</div>
			</div>
		</div>
	);
}

export default Vouchers;
