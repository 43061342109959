import { Form } from 'antd';
import axios from 'axios';
import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { setUser } from '../../store/user';
import { API_ENDPOINT } from '../../utils/config';
import { getErrorValue } from '../../utils/getErrors';
import headers from '../../utils/headers';
import FormItem from '../reusable/FormItem';
import Input from '../reusable/Input';
import SubmitButton from '../reusable/SubmitButton';
import { Toast } from '../reusable/Toast';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email tidak valid').required('Email wajib diisi'),
	password: Yup.string().required('Password wajib diisi'),
});

function Login() {
	const initialState = {
		email: '',
		password: '',
	};
	const navigate = useNavigate();

	const [input, setInput] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState({});
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (values, { resetForm }) => {
		try {
			setLoading(true);
			const response = await axios.post(`${API_ENDPOINT}/login`, values, { headers });
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
			setErrorMessage({});
			dispatch(setUser({ user: response.data.data.user, token: response.data.data.token }));
			navigate({ pathname: '/dashboard' });
		} catch (error) {
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="antialiased h-screen flex flex-col text-gray-custom">
			{/* HEADER  */}
			<nav className="flex items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img className="w-44" src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
				<div className="flex gap-6 items-center">
					<div className="text-lg">Belum Punya Akun?</div>
					<Link to="/register" className="border rounded-xl text-secondary border-secondary font-medium py-3 px-6">
						Daftar
					</Link>
				</div>
			</nav>
			<div
				className="flex-grow flex flex-col items-center justify-center w-full bg-contain bg-no-repeat bg-bottom"
				style={{ backgroundImage: "url('/images/bg-small.png')" }}
			>
				<div className="w-144 text-lg">
					<div className="rounded-xl drop-shadow-xl bg-white p-16">
						<h1 className="font-bold text-center mb-8 text-3xl">Login Super Admin</h1>
						<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
							{({
								isSubmitting,
								handleSubmit,
								handleBlur,
								handleChange,
								errors,
								touched,
								values,
								setFieldValue,
								setFieldTouched,
								isValid,
								dirty,
							}) => (
								<Form onFinish={handleSubmit} layout="vertical">
									{/* EMAIL */}
									<FormItem
										label="Email"
										error={getErrorValue(errors.email, errorMessage?.email)}
										touched={touched.email}
									>
										<Input
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											name="email"
											placeholder="Masukkan email anda"
											image={'/images/email.png'}
										/>
									</FormItem>
									{/* Password */}
									<FormItem
										label="Password"
										error={getErrorValue(errors.password, errorMessage?.password)}
										touched={touched.password}
									>
										<Input
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											name="password"
											placeholder="Masukkan password anda"
											image={'/images/password.png'}
											type="password"
										/>
									</FormItem>
									<SubmitButton className="py-3 mb-4 mt-8" dirty={dirty} isValid={isValid} isSubmitting={isSubmitting}>
										Login
									</SubmitButton>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
