import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { ConfirmModal } from '../../reusable/PopupModal';
import { Toast } from '../../reusable/Toast';
import CreateSkillModal from './_partials/CreateSkillModal';
import EditSkillModal from './_partials/EditSkillModal';

function Skills() {
	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			render: (text, record, index) => <>{(paginationState.current - 1) * paginationState.pageSize + index + 1}</>,
		},
		{
			title: 'Nama Skill',
			render: (text, record) => <div>{record.name ?? '-'}</div>,
		},
		{
			title: 'Status',
			render: (text, record) => <div>{record.is_active ? 'Aktif' : 'Tidak Aktif'}</div>,
		},
		{
			title: 'Dibuat Pada',
			sorter: true,
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text, record) => <>{moment(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY, HH:mm')}</>,
		},
		{
			title: 'Action',
			render: (text, record) => (
				<div className="flex gap-5 items-center">
					<button onClick={() => setSkillModal(record)} className="text-primary">
						<EditOutlined className="text-xl" />
					</button>
					<button onClick={() => onDelete(record.id)} className="text-red-custom">
						<DeleteOutlined className="text-xl" />
					</button>
				</div>
			),
		},
	];

	const [paginationState, setPaginationState] = useState({
		current: 1,
		pageSize: 10,
	});
	const [loading, setLoading] = useState(false);
	const [createModal, setCreateModal] = useState(false);
	const [data, setData] = useState([]);
	const [skillModal, setSkillModal] = useState({});

	const fetchData = async (pagination, sorter) => {
		try {
			setLoading(true);
			let response;
			response = await axiosConfig.get(`${API_ENDPOINT}/skills`, {
				params: { page: pagination.current, limit: pagination.pageSize },
			});
			setData(response.data.data.skills.data);
			setPaginationState({
				current: response.data.data.skills.current_page,
				pageSize: response.data.data.skills.per_page,
				total: response.data.data.skills.total,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				iconColor: '#FA8072',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData(paginationState);
	}, []);

	const handleTableChange = (pagination, filters, sorter) => {
		if (isEmpty(sorter)) {
			fetchData(pagination, {});
		} else {
			fetchData(pagination, { [sorter.field]: sorter.order });
		}
	};

	const onDelete = async id => {
		const confirm = await ConfirmModal.fire({
			icon: 'info',
			title: 'Apakah anda yakin ingin menghapus Skill?',
			iconColor: '#31AAE0',
			confirmButtonColor: '#FA8072',
			confirmButtonText: 'Yakin',
			cancelButtonColor: '#8aa2ae',
		});
		if (confirm.isConfirmed) {
			try {
				setLoading(true);
				const response = await axiosConfig.delete(`${API_ENDPOINT}/skills/${id}`);
				Toast.fire({
					icon: 'success',
					title: 'Berhasil Menghapus Skill',
					iconColor: '#31AAE0',
				});
				fetchData(paginationState);
			} catch (error) {
				console.log(error);
				Toast.fire({
					icon: 'error',
					title: error.response?.data.info ?? 'Terjadi Suatu Error',
					iconColor: '#FA8072',
				});
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<div>
			<EditSkillModal
				skillModal={skillModal}
				setSkillModal={setSkillModal}
				fetchData={fetchData}
				paginationState={paginationState}
			/>
			<CreateSkillModal
				createModal={createModal}
				setCreateModal={setCreateModal}
				fetchData={fetchData}
				paginationState={paginationState}
			/>
			{/* ---------- Main Content ---------- */}
			<div className="m-10 p-6 bg-white rounded-lg">
				<div>
					<h1 className="text-3xl font-bold">Skills</h1>
					<h2 className="text-xl mt-2">List Skill yang akan ditampilkan ke user</h2>
				</div>
				<div className="flex justify-end">
					<button
						onClick={() => setCreateModal(true)}
						className="text-lg bg-secondary text-white border-2 border-secondary rounded-xl py-2 px-4 font-bold flex justify-center items-center gap-4"
					>
						<span className="text-3xl">+</span> <span>Tambah Skill</span>
					</button>
				</div>
				<div className="w-full my-6">
					<Table
						columns={columns}
						rowKey={record => record.id}
						dataSource={data}
						pagination={paginationState}
						loading={loading}
						onChange={handleTableChange}
					/>
				</div>
			</div>
		</div>
	);
}

export default Skills;
