import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import BannedPencaker from './components/pages/ban/BannedPencaker';
import BannedPerusahaan from './components/pages/ban/BannedPerusahaan';
import Dashboard from './components/pages/Dashboard';
import Jobs from './components/pages/jobs/Jobs';
import JobsVerification from './components/pages/JobsVerification';
import Login from './components/pages/Login';
import Pencaker from './components/pages/pencaker/Pencaker';
import Perusahaan from './components/pages/Perusahaan/Perusahaan';
import Skills from './components/pages/Skills/Skills';
import TransactionsVerification from './components/pages/TransactionsVerification';
import Vouchers from './components/pages/vouchers/Vouchers';
import Layout from './components/reusable/Layout';

function App() {
	return (
		<BrowserRouter className="App bg-gray-400">
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/dashboard">
					<Route
						path=""
						element={
							<Layout>
								<Dashboard />
							</Layout>
						}
					/>
					<Route
						path="jobs"
						element={
							<Layout>
								<Jobs />
							</Layout>
						}
					/>
					<Route
						path="jobs-verification"
						element={
							<Layout>
								<JobsVerification />
							</Layout>
						}
					/>
					<Route
						path="transactions-verification"
						element={
							<Layout>
								<TransactionsVerification />
							</Layout>
						}
					/>
					<Route
						path="skills"
						element={
							<Layout>
								<Skills />
							</Layout>
						}
					/>
					<Route
						path="vouchers"
						element={
							<Layout>
								<Vouchers />
							</Layout>
						}
					/>
					<Route
						path="pencaker"
						element={
							<Layout>
								<Pencaker />
							</Layout>
						}
					/>
					<Route
						path="perusahaan"
						element={
							<Layout>
								<Perusahaan />
							</Layout>
						}
					/>
					<Route
						path="ban/perusahaan"
						element={
							<Layout>
								<BannedPerusahaan />
							</Layout>
						}
					/>
					<Route
						path="ban/pencaker"
						element={
							<Layout>
								<BannedPencaker />
							</Layout>
						}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
